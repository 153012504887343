import React from 'react';

function Question(props) {
  return (
    <div style={styles.questionContainer}>
      <div style={styles.question}>Q: {props.text}</div>
    </div>
  );
}

const styles = {
  questionContainer: {
    opacity: 0.79,
    backgroundColor: '#FFFDFD',
    color: 'white',
    width: '100%',
    height: '40px',
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
  },
  question: {
    paddingLeft: '10px',
    color: "#494848",
    fontWeight: '700',
  },
}

export default Question;
