import React, { Component, useState, useEffect } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Icon from '../components/icon';
import LogoButtonStyle from '../components/logo-button-style';
import FilterButtons from '../components/filter-buttons';


// One item component
// selected prop will be passed
const MenuItem = ({text, content, selected}) => {
  return <div style={styles.menuItem} className={`menu-item ${selected ? 'active' : ''}`}>
    {content}
  </div>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected) =>
  list.map(el => {
    const {name, content} = el;

    return <MenuItem content={content} key={name} selected={selected} />;
  });

const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};

const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

class SideScrollList extends Component {
  constructor(props) {
    super(props);
    // call it again if items count changes
    this.menuItems = Menu(props.list, props.selected);
    this.state = {
      selected: props.selected
    }
  }

  onSelect = key => {
    this.setState({ selected: key });
  }


  render() {
    const { selected } = this.state;
    // Create menu from items
    const menu = this.menuItems;

    return (
      <div className="App">
        <ScrollMenu
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={selected}
          onSelect={this.onSelect}
        />
      </div>
    );
  }
}

const styles = {
  menuItem: {
    margin: '10px',
    width: '500px',
  }
}

export default SideScrollList;
