import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from './icon.js';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  largeWhitePlayButton: {
    '& svg': {
      fontSize: 40,
      color: 'rgba(255,255,255, 0.7)'
    }
  },
}));

function YouTubeThumbnailForVideo(props) {
  const fileName = `https://cdn.gojibe.com/level/${props.src}`;
  const classes = useStyles();

  return (
    <div className="grow result" style={styles.thumbnail}>
      <iframe style={styles.image} width="340" height="200" src="https://www.youtube.com/embed/tZ2fsBQrw0k?start=35&end=65" title="YouTube Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </div>
  );
}

const styles = {
  content: {
  },
}

export default YouTubeThumbnailForVideo;
