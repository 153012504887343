import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import EmailSignup from '../components/email-signup.js';
import Header from '../components/header'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { allVideos } from '../data';
import { identify, track } from '../helpers/analytics.js';
import SideScrollList from '../components/side-scroll-list';
import YoutubeThumbnailForGuide from '../components/youtube-thumbnail-for-guide'
import '../styles/guide.css'
import Upsell from '../components/upsell'
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap//FormControl';
import LogoButtonStyle from '../components/logo-button-style'
import { firebase } from '../modules/firebase-module.js';
import VideoInSideScroll from '../components/video-in-side-scroll';
import { Link } from "@reach/router"
import { Center, Button, ChakraProvider, Box, Heading, Image } from "@chakra-ui/react"
//import './WarmIntro.css';

function WarmIntroForm(props) {

  return (
    <ChakraProvider>
      <Center mt="20">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScqegNSRgT1W0kw0DrrWhakaFoajhyjW8OUL4I5i3woGOJCsA/viewform?embedded=true" width="640" height="1640" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </Center>
    </ChakraProvider>
  );
}

export default WarmIntroForm;
