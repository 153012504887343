export const addRemoveItem = function(list, item) {
  let itemIndex = list.indexOf(item)

  if (itemIndex === -1) {
    list.push(item);
  } else {
    list.splice(itemIndex, 1);
  }

  return [...list];
}

export default {
  addRemoveItem,
}
