import React from 'react';
import PhoneFrame from './phone-frame';

function Phone(props) {

  let innerScreenStyles = {
    backgroundColor: props.backgroundColor || 'white',
    ...styles.innerScreen,
  }

  return (
    <div style={styles.phoneContainer}>
      <div style={styles.contentContainer}>
        <div style={styles.innerScreen}>
          { props.children }
        </div>
      </div>
      <PhoneFrame />
    </div>
  );
}

const styles = {
  innerScreen: {
    display: 'flex',
    borderRadius: '5px',
    margin: '10px 10px 10px 9px',
    //backgroundColor: 'black', // DEBUG
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '292px',
  },
  phoneContainer: {
    position: 'relative',
    height: '648px',
    width: '310px',
  },
  contentContainer: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    // backgroundColor: 'yellow', // DEBUG
  },
  phone: {
    zIndex: 3,
    position: 'absolute',
    height: '648px',
    //width: '366px',
  }
}

export default Phone;
