import React from 'react';

function TopControls(props) {
  return (
    <div style={styles.topControls}>
      { props.children }
    </div>
  );
}

const styles = {
  topControls: {
    zIndex: 2,
  },
}

export default TopControls;
