import React from 'react';

function Info(props) {

  let stats = props.stats || [];

  let statsList = stats.map((stat, index) => {
    return (
      <div style={styles.stat} key={`stat-${index}`}>
        <div style={styles.statValue}>{stat.value}</div>
        <div style={styles.description}>{stat.description}</div>
      </div>
    )
  })

  return (
    <div style={styles.stats}>
      { statsList }
    </div>
  );
}

const styles = {
  description: {
    fontSize: '11px',
    color: '#939090'
  },
  stat: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: 'black',
    paddingTop: '30px',
    paddingBottom: '7px',
    width: '100%',
    color: 'white',
  },
}

export default Info;
