import React from 'react';

function BottomNav(props) {
  return (
    <div style={styles.bottomNav}>
      <img style={styles.icon} src="/icons/search.svg" />
      <img style={styles.icon} src="/icons/home.svg" />
      <img style={styles.icon} src="/icons/plus-circle.svg" />
      <img style={styles.icon} src="/icons/mail.svg" />
      <img style={styles.icon} src="/icons/user-circle.svg" />
    </div>
  );
}

const styles = {
  icon: {
    width: '30px',
  },
  bottomNav: {
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'black',
  },
}

export default BottomNav;
