import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import EmailSignup from '../components/email-signup.js';
import Header from '../components/header'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { allVideos } from '../data';
import { identify, track } from '../helpers/analytics.js';
import SideScrollList from '../components/side-scroll-list';
import YoutubeThumbnailForGuide from '../components/youtube-thumbnail-for-guide'
import '../styles/guide.css'
import Upsell from '../components/upsell'
import IconButton from '@material-ui/core/IconButton';
import FormControl from 'react-bootstrap//FormControl';
import LogoButtonStyle from '../components/logo-button-style'
import { firebase } from '../modules/firebase-module.js';
import { getConnectorEmailLink } from '../helpers/emails';
import VideoInSideScroll from '../components/video-in-side-scroll';
import { Link } from "@reach/router"
import { Textarea, Center, Button, Stack, InputGroup, InputLeftElement, ChakraProvider, Input, Box, Heading, Image, Icon } from "@chakra-ui/react"
import { EmailIcon, AddIcon, LinkIcon, WarningIcon } from '@chakra-ui/icons'
import RichIntroWidget from '../components/rich-intro-widget'
import Person from '@material-ui/icons/Person';
import PersonOutline from '@material-ui/icons/PersonOutline';
//import './WarmIntro.css';

function WarmIntroFormCustom(props) {

  let [ requesterName, setRequesterName] = useState('');
  let [ requesterEmail, setRequesterEmail ] = useState('');
  let [ requesterBio, setRequesterBio] = useState('');
  let [ requesterLinkedIn, setRequesterLinkedIn] = useState('');
  let [ targetName, setTargetName] = useState('');
  let [ targetEmail, setTargetEmail ] = useState('');
  let [ targetLinkedIn, setTargetLinkedIn] = useState('');
  let [ explanation, setExplanation ] = useState('');
  let [ connectorEmail, setConnectorEmail ] = useState('');
  let [ connectorName, setConnectorName ] = useState('');


  let connectorEmailLink = getConnectorEmailLink({
    requesterName,
    requesterEmail,
    requesterLinkedIn,
    targetName,
    targetEmail,
    targetLinkedIn,
    explanation,
    connectorName,
    connectorEmail,
    askPermission: false,
  });

  function onChangeName(e) {
    let value = e.target.value;
    setRequesterName(value);
  }

  function onChangeEmail(e) {
    let value = e.target.value;
    setRequesterEmail(value);
  }

  function onChangeBio(e) {
    let value = e.target.value;
    setRequesterBio(value);
  }

  function onChangeLinkedIn(e) {
    let value = e.target.value;
    setRequesterLinkedIn(value);
  }

  function onChangeTargetName(e) {
    let value = e.target.value;
    setTargetName(value);
  }

  function onChangeTargetEmail(e) {
    let value = e.target.value;
    setTargetEmail(value);
  }

  function onChangeExplanation(e) {
    let value = e.target.value;
    setExplanation(value);
  }

  function onChangeConnectorName(e) {
    let value = e.target.value;
    setConnectorName(value);
  }

  function onChangeConnectorEmail(e) {
    let value = e.target.value;
    setConnectorEmail(value);
  }

  return (
    <ChakraProvider>
      <Center m="20" d="flex" flexDirection="column">
        <Heading mb="10">
          Intro Request
        </Heading>
        <Stack mb="10" spacing={3} width="25rem">
          <Heading size="md">
            Requestor
          </Heading>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={PersonOutline} color="gray.300" />}
            />
            <Input
              onChange={onChangeName}
              value={requesterName}
              type="tel"
              placeholder="Requestor Name" />
          </InputGroup>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={PersonOutline} color="gray.300" />}
            />
            <Input
              onChange={onChangeEmail}
              value={requesterEmail}
              type="tel"
              placeholder="Requestor Email" />
          </InputGroup>
          <Textarea
            p="2"
            value={''}
            placeholder="Requestor Bio"
            onChange={onChangeBio}
            value={requesterBio}
            size="med"
          />
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={PersonOutline} color="gray.300" />}
            />
            <Input 
              onChange={onChangeLinkedIn}
              value={requesterLinkedIn}
              type="tel"
              placeholder="Requestor LinkedIn"
            />
          </InputGroup>
        </Stack>
        <Stack mb="10" spacing={3} width="25rem">
          <Heading size="md">
            Target
          </Heading>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={Person} color="gray.300" />}
            />
            <Input
              onChange={onChangeTargetName}
              value={targetName}
              type="tel"
              placeholder="Full Name of who you'd like to meet"
            />
          </InputGroup>
          { /*
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={Person} color="gray.300" />}
            />
            <Input
              onChange={onChangeLinkedIn}
              value={targetLinkedIn}
              type="tel"
              placeholder="LinkedIn of who you'd like to meet"
            />
          </InputGroup> */}
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={Person} color="gray.300" />}
            />
            <Input 
              onChange={onChangeTargetEmail}
              value={targetEmail}
              type="tel"
              placeholder="Email of who you'd like to meet"
            />
          </InputGroup>
          <Textarea
            p="2"
            value={''}
            placeholder="Message for target"
            onChange={onChangeExplanation}
            value={explanation}
            size="med"
          />
        </Stack>
        <Stack mb="10" spacing={3} width="25rem">
          <Heading size="md">
            Connector
          </Heading>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={LinkIcon} color="gray.300" />}
            />
            <Input 
              onChange={onChangeConnectorName}
              value={connectorName}
              type="tel"
              placeholder="Connector Name"
            />
          </InputGroup>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={LinkIcon} color="gray.300" />}
            />
            <Input 
              onChange={onChangeConnectorEmail}
              value={connectorEmail}
              type="tel"
              placeholder="Connector Email"
            />
          </InputGroup>
        </Stack>
        <a target="_blank" href={ connectorEmailLink }>Connector Email Link</a>
        <RichIntroWidget
          requesterBio={ requesterBio || ' '}
          requesterName={ requesterName || ' '}
          requesterEmail={ requesterEmail || ' ' }
          requesterLinkedIn={ requesterLinkedIn || ' ' }
          connectorName={ connectorName || ' '}
          connectorEmail={ connectorEmail || ' ' }
          targetName={ targetName || ' '}
          targetEmail={ targetEmail || ' '}
          targetLinkedIn={ targetLinkedIn || ' ' }
          explanation={ explanation || ' '}
        />
      </Center>
    </ChakraProvider>
  );
}

export default WarmIntroFormCustom;
