import fb from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Configure Firebase
const config = {
  apiKey: "AIzaSyDD81cFL0dzN0gFVXwCv1QRN4YXvtNFL_Q",
  authDomain: "level-71377.firebaseapp.com",
  databaseURL: "https://level-71377.firebaseio.com",
  projectId: "level-71377",
  storageBucket: "level-71377.appspot.com",
  messagingSenderId: "204041575016",
  appId: "1:204041575016:web:9decb272476838e3c7b9dd",
  measurementId: "G-G79WLT03GX"
};

fb.initializeApp(config);

export const firebase = fb;
export const db = fb.firestore();

db.settings({})

export default {
  firebase,
  db
}
