import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import Header from '../components/header'
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { firebase } from '../modules/firebase-module.js';

function InputComponent(props) {

  let [type, setType] = useState('text');

  function selectType(type) {
    setType(type);
  }

  useEffect(() => {
  }, []);


  let options = (
    <div style={styles.options}>
      <ButtonGroup
        orientation="vertical"
        color="primary"
        aria-label="vertical outlined primary button group"
      >
        <Button
          onClick={selectType.bind(null, 'text')}
          startIcon={(<Icon id="text" />)}
          variant={type === 'text' && 'contained'}
        >
          Text
        </Button>
        <Button
          onClick={selectType.bind(null, 'audio')}
          color="primary"
          variant={type === 'audio' && 'contained'}
          startIcon={(<Icon id="mic" />)}
        >
          Audio
        </Button>
        <Button
          onClick={selectType.bind(null, 'video')}
          startIcon={(<Icon id="video" />)}
          variant={type === 'video' && 'contained'}
        >
          Video
        </Button>
      </ButtonGroup>
    </div>
  );

  let inputComponent = null;
  if (type === 'text') {
    inputComponent = (
      <textarea style={styles.textInput} id="firepad"></textarea>
    )
  }

  if (type === 'audio') {
    inputComponent = (
      <div>Audio</div>
    )
  }

  if (type === 'video') {
    inputComponent = (
      <div>Video</div>
    )
  }

  return (
    <div>
      <div style={styles.heading}>Answer with your preferred method:</div>
      <div style={styles.container}>
        { options }
        <div style={styles.inputContainer}>
          { inputComponent }
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
  },
  inputContainer: {
    flex: 1,
    position: 'relative',
    paddingLeft: '15px',
  },
  heading: {
    fontSize: '12px',
  },
  sidebar: {
  },
  options: {
    margin: '10px',
  },
  textInput: {
    width: '100%',
    maxHeight: '128px',
    minHeight: '128px',
  },
}

export default InputComponent;
