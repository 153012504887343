import React from 'react';

function Profile(props) {
  let info = props.info || {};

  return (
    <div style={styles.profileContainer}>
      <div style={styles.profileDetails}>
        <div style={styles.title}> { info.title }</div>
        <div style={styles.company}> { info.company }</div>
      </div>
    </div>
  );
}

const styles = {
  title: {
    fontSize: '16px',
    paddingTop: '10px',
    fontWeight: '600',
    paddingTop: '5px',
  },
  company: {
    fontSize: '12px',
    marginBottom: '8px',
  },
  subDetails: {
    fontSize: '11px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  profileContainer: {
    color: '#E0E0E0',
    backgroundColor: '#232222',
    width: '100%',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
  },
  profileDetails: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    justifyContent: 'space-around',
  }
}

export default Profile;
