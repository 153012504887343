import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import Container from '../components/container.js';
import Phone from '../components/phone.js';
import Mock from '../components/mock';
import Video from '../components/video';
import SideControls from '../components/side-controls';
import Info from '../components/info';
import BottomNav from '../components/bottom-nav';
import Question from '../components/question';
import Profile from '../components/profile';
import TopControls from '../components/top-controls'
import BottomControls from '../components/bottom-controls'
import WelcomeScreen from '../components/welcome-screen';
import EndScreen from '../components/end-screen';
import StaticImage from '../components/static-screen';
import CarouselControlNext from '../components/carousel-control-next';
import CarouselControlPrev from '../components/carousel-control-prev';
import Carousel from 'react-bootstrap/Carousel';
import Tooltip from '../components/tooltip';
import { data, profiles, tooltipText } from '../data';
import { track } from '../helpers/analytics.js';
import { useQuery } from '../helpers/query-params.js'

function Demo() {
  const videoTrack = useQuery('track') || 'default';

  const [carouselIndex, setIndex] = useState(0);

  const VIDEO_TRACK_DATA = data[videoTrack];
  const lastIndex = VIDEO_TRACK_DATA.length + 1
  const firstIndex = 0

  const prevEnabled = carouselIndex > 0
  const nextEnabled = carouselIndex < lastIndex;

  const isFirstScreen = carouselIndex === 0;
  const isLastScreen = carouselIndex === lastIndex;

  function previous() {
    let prevIndex = carouselIndex === firstIndex
      ? carouselIndex
      : carouselIndex - 1

    // Necessary for autoplay on safari
    let videoEl = document.getElementById(`video-${prevIndex}`);
    if (videoEl) videoEl.play();

    setIndex(prevIndex)
  }

  function next() {
    let nextIndex = carouselIndex === lastIndex
      ? carouselIndex
      : carouselIndex + 1

    // Necessary for autoplay on safari
    let videoEl = document.getElementById(`video-${nextIndex}`);
    if (videoEl) videoEl.play();

    setIndex(nextIndex)
  }

  useEffect(() => {
    if (isLastScreen) {
      track('Viewed Last Screen');
    } else if (isFirstScreen) {
      track(`Viewed Home Screen`);
    } else {
      track(`Viewed - Video ${carouselIndex}`);
    }
  }, [carouselIndex])

  useEffect(() => {
    function handleKeyDown(event) {
      switch (event.keyCode) {
        case 37: // Left Arrow
          previous();
          break;
        case 39: // Right Arrow
          next();
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [carouselIndex])

  let videoScreens = VIDEO_TRACK_DATA.map((info, i) => (
    <Carousel.Item key={`carousel-${i}`}>
      <TopControls>
        <Info stats={ info.profile && info.profile.stats }/>
      </TopControls>
      <Video index={i + 1} activeIndex={carouselIndex} src={ info.videoSrc }/>
      <BottomControls>
        <SideControls views={ info.views } />
        <Question text={ info.question } />
        <Profile info={ info.profile }/>
        <BottomNav />
      </BottomControls>
    </Carousel.Item>
  ));

  let carouselControlNext = (
    <>
      <Tooltip
        onClicked={next}
        offsetX={-110}
        offsetY={-60}
        direction={'none'}
        text={tooltipText[carouselIndex]}
        shouldShowTooltip={() => (nextEnabled)}
      />
      <CarouselControlNext
        onClick={next}
        enabled={nextEnabled}
      />
    </>
  )

  let carouselControlPrev = (
    <CarouselControlPrev
      next={next}
      onClick={previous}
      enabled={prevEnabled}
    />
  )

  if (isLastScreen) {
    return (
      <Container>
        { carouselControlNext }
          <EndScreen />
        { carouselControlPrev }
      </Container>
    )
  }

  let phoneBackground = carouselIndex === 1 ? 'white' : 'black'
  let staticInfo = VIDEO_TRACK_DATA[0];

  let welcomePageStaticImage = (
    <Carousel.Item>
      <TopControls>
        <Info stats={ staticInfo.profile && staticInfo.profile.stats }/>
      </TopControls>
      <StaticImage src={ staticInfo.imageSrc } clickHandler={next} />
      <BottomControls>
        <SideControls views={ staticInfo.views } />
        <Question text={ staticInfo.question } />
        <Profile info={ staticInfo.profile }/>
        <BottomNav />
      </BottomControls>
    </Carousel.Item>
  )

  return (
    <Container>
      { carouselControlPrev }
      <Phone backgroundColor={phoneBackground}>
        <Carousel
          activeIndex={carouselIndex}
          controls={false}
          indicators={false}
          slide={false}
          wrap={false}
          keyboard={true}
          pause={false}
          interval={null}
        >
          { welcomePageStaticImage }
          { videoScreens }
        </Carousel>
      </Phone>
      { carouselControlNext }
    </Container>
  );
}

const styles = {
}

export default Demo;
