import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import FilterRow from '../components/filter-row';
import ResultsList from '../components/results-list';
import './App.css';
import logo from '../logo.svg';
import { addRemoveItem } from '../helpers/add-remove-filter';
import { industryFilters, roleFilters, topicFilters } from '../data.js'

function App() {
  let [searchText, setSearchText] = useState(undefined);
  let [currentIndustryFilters, setIndustryFilter] = useState([])
  let [currentRoleFilters, setRoleFilters] = useState([])
  let [currentTopicFilters, setTopicFilters] = useState([])

  function onFilterIndustry(filter) {
    if (filter === 'all-industry') {
      setIndustryFilter([]);
    } else {
      setIndustryFilter(addRemoveItem(currentIndustryFilters, filter));
    }
  }

  function onFilterRole(filter) {
    if (filter === 'all-roles') {
      setRoleFilters([]);
    } else {
      setRoleFilters(addRemoveItem(currentRoleFilters, filter));
    }
  }

  function onFilterTopic(filter) {
    if (filter === 'all-topics') {
      setTopicFilters([]);
    } else {
      setTopicFilters(addRemoveItem(currentTopicFilters, filter));
    }
  }

  let currentIndustrySelection = currentIndustryFilters.length === 0 ? ['all-industry'] : currentIndustryFilters;
  let currentRoleSelection = currentRoleFilters.length === 0 ? ['all-roles'] : currentRoleFilters;
  let currentTopicSelection = currentTopicFilters.length === 0 ? ['all-topics'] : currentTopicFilters;

  return (
    <div>
      <div style={styles.headerGroup}>
        <Header showSearch={false} />
        <div style={styles.center}>
          <FilterRow
            type="industry"
            filters={industryFilters}
            currentFilters={currentIndustrySelection}
            onFilterClick={onFilterIndustry}
          />
          <FilterRow
            type="role"
            filters={roleFilters}
            currentFilters={currentRoleSelection}
            onFilterClick={onFilterRole}
          />
          <FilterRow
            type="topic"
            filters={topicFilters}
            currentFilters={currentTopicSelection}
            onFilterClick={onFilterTopic}
          />
        </div>
      </div>
      <div style={styles.content}>
        <ResultsList
          selectedIndustries={currentIndustrySelection}
          selectedRoles={currentRoleSelection}
          selectedTopics={currentTopicSelection}
        />
      </div>
    </div>
  );
}

const styles = {
  headerGroup: {
    width: '100%',
    position: 'fixed',
    backgroundColor: 'white',
    zIndex: 2,
  },
  content: {
    paddingTop: '275px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
  },
}

export default App;
