import React from 'react';

function LogoButtonStyle(props) {
  return (
    <svg width="79" height="37" viewBox="0 0 79 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3694 0.000192317C6.87453 0.046117 0 8.31117 0 18.5C0 28.6889 6.87453 36.9539 15.3694 36.9998V37H15.4405H62.6871H62.7582V36.9998C71.2531 36.9539 78.1276 28.6889 78.1276 18.5C78.1276 8.31118 71.2531 0.0461195 62.7582 0.000192338V0H62.6871H15.4405H15.3694V0.000192317Z" fill="black"/>
    <path d="M14.7015 8.97313V27.2265H12.4568V8.97313H14.7015Z" fill="white"/>
    <path d="M30.8605 19.9498C30.8605 20.3774 30.8358 20.8296 30.7865 21.3065H19.9825C20.0647 22.6385 20.5169 23.6827 21.3391 24.4391C22.1778 25.1791 23.1891 25.5491 24.3731 25.5491C25.3434 25.5491 26.1491 25.3271 26.7905 24.8831C27.4483 24.4227 27.9087 23.8143 28.1718 23.0578H30.5891C30.2274 24.3569 29.5038 25.4176 28.4185 26.2398C27.3331 27.0456 25.9847 27.4485 24.3731 27.4485C23.0905 27.4485 21.9394 27.1607 20.9198 26.5851C19.9167 26.0096 19.1274 25.1956 18.5518 24.1431C17.9762 23.0743 17.6885 21.8409 17.6885 20.4431C17.6885 19.0454 17.968 17.8202 18.5271 16.7678C19.0862 15.7154 19.8674 14.9096 20.8705 14.3505C21.89 13.7749 23.0576 13.4871 24.3731 13.4871C25.6558 13.4871 26.7905 13.7667 27.7771 14.3258C28.7638 14.8849 29.5203 15.6578 30.0465 16.6445C30.5891 17.6147 30.8605 18.7165 30.8605 19.9498ZM28.5418 19.4811C28.5418 18.626 28.3527 17.8942 27.9745 17.2858C27.5963 16.6609 27.0783 16.1922 26.4205 15.8798C25.7791 15.5509 25.0638 15.3865 24.2745 15.3865C23.1398 15.3865 22.1696 15.7482 21.3638 16.4718C20.5745 17.1954 20.1222 18.1985 20.0071 19.4811H28.5418Z" fill="white"/>
    <path d="M38.8554 25.1545L43.0487 13.7091H45.4414L40.138 27.2265H37.5234L32.22 13.7091H34.6374L38.8554 25.1545Z" fill="white"/>
    <path d="M59.9835 19.9498C59.9835 20.3774 59.9589 20.8296 59.9095 21.3065H49.1055C49.1878 22.6385 49.64 23.6827 50.4622 24.4391C51.3009 25.1791 52.3122 25.5491 53.4962 25.5491C54.4664 25.5491 55.2722 25.3271 55.9135 24.8831C56.5713 24.4227 57.0318 23.8143 57.2949 23.0578H59.7122C59.3504 24.3569 58.6269 25.4176 57.5415 26.2398C56.4562 27.0456 55.1078 27.4485 53.4962 27.4485C52.2135 27.4485 51.0624 27.1607 50.0429 26.5851C49.0398 26.0096 48.2504 25.1956 47.6749 24.1431C47.0993 23.0743 46.8115 21.8409 46.8115 20.4431C46.8115 19.0454 47.0911 17.8202 47.6502 16.7678C48.2093 15.7154 48.9904 14.9096 49.9935 14.3505C51.0131 13.7749 52.1806 13.4871 53.4962 13.4871C54.7789 13.4871 55.9135 13.7667 56.9002 14.3258C57.8869 14.8849 58.6433 15.6578 59.1695 16.6445C59.7122 17.6147 59.9835 18.7165 59.9835 19.9498ZM57.6649 19.4811C57.6649 18.626 57.4758 17.8942 57.0975 17.2858C56.7193 16.6609 56.2013 16.1922 55.5435 15.8798C54.9022 15.5509 54.1869 15.3865 53.3975 15.3865C52.2629 15.3865 51.2926 15.7482 50.4869 16.4718C49.6975 17.1954 49.2453 18.1985 49.1302 19.4811H57.6649Z" fill="white"/>
    <path d="M65.1911 8.97313V27.2265H62.9464V8.97313H65.1911Z" fill="white"/>
    </svg>
  );
}

const styles = {
}

export default LogoButtonStyle;
