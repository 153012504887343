import React from 'react';
import { allVideos, roleFilters, roleTextMap } from '../data.js';
import EmptyFrame from '../components/empty-frame'
import Info from '../components/info';
import BottomNav from '../components/bottom-nav';
import Question from '../components/question';
import Profile from '../components/profile';
import Spinner from 'react-bootstrap/Spinner';
import TopControls from '../components/top-controls'
import BottomControls from '../components/bottom-controls'
import StaticImage from '../components/static-screen'
import ResultsCard from '../components/results-card'
import { CSSTransitionGroup } from 'react-transition-group'


function ResultsList({ results, searchText }) {

  let organizedResultsMap = {};
  (results || []).map((result) => {
    if (organizedResultsMap[result.field]) {
      organizedResultsMap[result.field].push(result);
    } else {
      organizedResultsMap[result.field] = [];
      organizedResultsMap[result.field].push(result);
    }
  })

  let sortedKeys = Object.keys(organizedResultsMap).sort((a,b) => {
    let aSize = organizedResultsMap[a].length;
    let bSize = organizedResultsMap[b].length;
    if (aSize > bSize) return -1
    if (aSize < bSize) return 1
    return 0
  });

  let resultsList = [];
  sortedKeys.forEach((section) => {
    let sectionResults = organizedResultsMap[section].map((result, i) => {
      let thumbnail = result.thumbnail === '_IMAGE_' ? './portrait_placeholder.png' : result.thumbnail;
      return (
        <ResultsCard key={result.id} id={result.id} index={i} src={thumbnail} data={result}/>
      )
    })

    if (sectionResults.length > 0) {
      resultsList.push(
        <div key={`section-group-${section}`}>
          <div key={`section-header-${section}`} style={styles.header}>{section}</div>
          {sectionResults}
        </div>
      )
    }
  })

  if (!searchText && resultsList.length === 0) {
    return (
      <div style={styles.center}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }

  if (resultsList.length === 0) {
    return (
      <div style={styles.center}>
        <div style={styles.title}>
          Sorry, no results :(
        </div>
        <div style={styles.subtext}>
          If you'd like to see content, request it from hello@trylevel.app !
        </div>
      </div>
    )
  }

  return (
    <div style={styles.results}>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}>
        { resultsList }
        <div>Search by <a href="https://www.algolia.com/">Algolia</a></div>
      </CSSTransitionGroup>
    </div>
  );
}

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '200px',
  },
  header: {
    fontSize: '20px',
    fontWeight: '700',
  },
  title: {
    fontSize: '20px',
  },
  subtext: {
    fontSize: '14px',
  },
  results: {
    marginLeft: '75px',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
  }
}

export default ResultsList;
