import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import Questionnaire from './questionnaire';
import Icon from './icon.js';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  largeWhitePlayButton: {
    '& svg': {
      fontSize: 75,
      color: 'rgba(255,255,255, 0.7)'
    }
  },
  largeBlackPlayButton: {
    '& svg': {
      fontSize: 75,
      color: 'rgba(0, 0, 0, 0.3)'
    }
  },
}));

function Video({ index, activeIndex, src }) {
  const videoEl = useRef(null);
  const fileName = `https://cdn.gojibe.com/level/${src}`;

  let INTERSTITIAL_INDEX = 3; // VIDEOS + 1
  let [hasSeenInterstitial, setHasSeenInterstitial] = useState(false);
  let [showPlayButton, setShowPlayButton] = useState(false);

  let showingInterstitial = (
    activeIndex === INTERSTITIAL_INDEX
    && index === activeIndex
    && !hasSeenInterstitial
  )

  const classes = useStyles();

  useEffect(() => {
    function handleKeyDown(event) {
      let video = videoEl.current;
      if (video) {
        if (index === activeIndex) {
          switch (event.keyCode) {
            /*
            case 77: // M Key
              video.muted = !video.muted;
              break;
            case 82: // R Key
              video.currentTime = 0;
              break;
            */
          }
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [activeIndex]);

  function playPauseVideo(event) {
    let video = videoEl.current;
    if (video.paused) {
      video.play();
      setShowPlayButton(false);
    } else {
      video.pause();
      setShowPlayButton(true);
    }
  }

  useEffect(() => {
    (function autoPlayPauseVideo(event) {
      let video = videoEl.current;
      if (video) {
        let noInterstitial = !(activeIndex === INTERSTITIAL_INDEX && !hasSeenInterstitial);
        if (index === activeIndex && noInterstitial) {
          setTimeout(() => {
            video.play();
          }, 300)
          setShowPlayButton(false);
        } else {
          video.pause();
          setShowPlayButton(true);
        }
      }
    })();
  }, [activeIndex]);

  let closeInterstitial = () => {
    setHasSeenInterstitial(true);
    let video = videoEl.current;
    //video.muted = false;
    video.play();
    setShowPlayButton(false);
  }

  let playButton = (
    <IconButton
      className={classes.largeWhitePlayButton}
      id="play-button"
      >
      <Icon id='play'/>
    </IconButton>
  )

  let interstitial = showingInterstitial
    ? (
        <Questionnaire
          cancelButton={(
            <Button
              onClick={closeInterstitial}
              type="submit"
              variant="secondary">
              { 'See More Content' }
            </Button>
          )}
          onClose={closeInterstitial}
        />
      )
    : null

  return (
    <>
      <video id={`video-${index}`} ref={videoEl} src={fileName} style={styles.video} controls playsInline />
      <div style={styles.videoClickCapture} onClick={playPauseVideo}>
        { showPlayButton ? playButton : null }
      </div>
      { interstitial }
    </>
  );
}

const styles = {
  videoClickCapture: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 4,
    backgroundColor: 'rgba(255,255,255,0)',
  },
  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
}

export default Video;
