import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from './icon.js';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  largeWhitePlayButton: {
    '& svg': {
      fontSize: 40,
      color: 'rgba(255,255,255, 0.7)'
    }
  },
}));

function ResultsCard(props) {
  const fileName = `https://cdn.gojibe.com/level/${props.src}`;
  const index = props.id % 80
  const gender = props.id % 2 === 0 ? 'men' : 'women';
  const image = `https://randomuser.me/api/portraits/${gender}/${index}.jpg`

  const classes = useStyles();

  return (
    <Link to={`/v3/${props.id}`} >
      <div className="grow result" style={styles.thumbnail}>
        <div style={styles.content}>
          <img  style={styles.image} src={image} />
          { /*
          <IconButton
            className={classes.largeWhitePlayButton}
            id="play-button"
            >
            <Icon id='play'/>
          </IconButton>
               */}
        </div>
        <div style={styles.details}>
          <div style={styles.title}>{props.data.title}</div>
          <div style={styles.company}>{props.data.company}</div>
        </div>
      </div>
    </Link>
  );
}

const styles = {
  details: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    color: 'gray',
  },
  company: {
    fontSize: '12px',
    color: 'gray',
  },
  image: {
    position: 'absolute',
    width: '120px',
    borderRadius: '10px',
  },
  thumbnail: {
    display: 'inline-block',
    width: '120px',
    //borderRadius: '20px',
    margin: '15px 15px 60px 15px',
    position: 'relative',
    height: '130px'
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default ResultsCard;
