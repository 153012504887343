import React, { useState, useEffect, useRef } from 'react';
import './rich-intro-widget.css'
import {getYesIntroLink} from '../helpers/emails.js'

function RichIntroWidget(props) {
  let {
    requesterName,
    requesterEmail,
    requesterBio,
    requesterLinkedIn,
    connectorName,
    connectorEmail,
    targetName,
    targetEmail,
    targetLinkedIn,
    explanation,
  } = props;

  let yesLink = getYesIntroLink({
    requesterName,
    requesterEmail,
    requesterBio,
    requesterLinkedIn,
    connectorName,
    connectorEmail,
    targetName,
    targetEmail,
    targetLinkedIn,
    explanation,
    askPermission: false,
  });

  return (
    <div id="rich-container">
      <div id="header">Are you open to making the introduction?</div>
      <hr class="hr"></hr>
      <div id="answer-options">
        <a class="link-container" href={yesLink}>
          <div class="response_button">
            ✓
          </div>
          <div class="text-container">
            <div class="title">Yes!</div>
            <div class="subtitle">Clicking Yes will draft an email making the intro</div>
          </div>
        </a>
        <a class="link-container"  href="trylevel.app">
          <div class="response_button">
            ✓*
          </div>
          <div class="text-container">
            <div class="title">Yes, but I'd like permission first</div>
            <div class="subtitle">Clicking Yes will draft an email for permission</div>
          </div>
        </a>
        <a class="link-container"  href="trylevel.app">
          <div class="response_button">
            ✕
          </div>
          <div class="text-container">
            <div class="title">No, I don't feel comfortable</div>
            <div class="subtitle">Clicking No will politely decline the request</div>
          </div>
        </a>
        <a class="link-container"  href="trylevel.app">
          <div class="response_button">
            ?
          </div>
          <div class="text-container">
            <div class="title">Maybe, can you provide more information?</div>
            <div class="subtitle">Clicking Maybe will draft an email for more information</div>
          </div>
        </a>
      </div>
      <hr class="hr"></hr>
      <a target="_blank" href="https://trylevel.app/connect">
        <div id="footer">
          <div id="powered-by-container">
            Introduction powered by <div class="colored-background" id="logo-text">level</div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default RichIntroWidget;
