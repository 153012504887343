import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from './icon.js';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  largeWhitePlayButton: {
    '& svg': {
      fontSize: 40,
      color: 'rgba(255,255,255, 0.7)'
    }
  },
}));

function VideoThumbnail(props) {
  const fileName = `https://cdn.gojibe.com/level/${props.src}`;
  const classes = useStyles();

  return (
    <Link to={`/v/${props.id}`}>
      <div className="grow result" style={styles.thumbnail}>
        <div style={styles.content}>
          <img  style={styles.image} src={fileName} />
          <IconButton
            className={classes.largeWhitePlayButton}
            id="play-button"
            >
            <Icon id='play'/>
          </IconButton>
        </div>
      </div>
    </Link>
  );
}

const styles = {
  image: {
    position: 'absolute',
    width: '120px',
    borderRadius: '10px',
  },
  thumbnail: {
    display: 'inline-block',
    width: '120px',
    //borderRadius: '20px',
    margin: '15px',
    position: 'relative',
    height: '212px'
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default VideoThumbnail;
