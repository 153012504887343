export const profiles = {
  constructionProjectManager: {
    title: 'Construction Project Manager',
    company: '5000 Person Company',
    field: 'Construction',
    stats: [
      {
        value: '5',
        description: 'years',
      },
      {
        value: '$100-150K',
        description: '/year',
      },
      {
        value: '4/5',
        description: 'Work-Life',
      },
      {
        value: '5/5',
        description: 'NPS',
      },
    ],
  },
  nurse: {
    title: 'Registered Nurse - Labor & Delivery',
    company: 'Over 1000 person company',
    field: 'Healthcare',
    stats: [
      {
        value: '6',
        description: 'years',
      },
      {
        value: '$100K-$150K',
        description: '/year',
      },
      {
        value: '4/5',
        description: 'Work-Life',
      },
      {
        value: '5/5',
        description: 'NPS',
      },
    ],
  },
  nurseA: {
    title: 'Registered Nurse - Bedside',
    company: '500 person company',
    field: 'Healthcare',
    stats: [
      {
        value: '8 years',
        description: 'years',
      },
      {
        value: '$60-75K',
        description: '/year',
      },
      {
        value: '4/5',
        description: 'Work-Life',
      },
      {
        value: '4/5',
        description: 'NPS',
      },
    ],
  },
  nurseB: {
    title: 'Registered Nurse - PACU',
    company: '800 person company',
    field: 'Healthcare',
    stats: [
      {
        value: '3',
        description: 'years',
      },
      {
        value: '$85-100K',
        description: '/year',
      },
      {
        value: '3/5',
        description: 'Work-Life',
      },
      {
        value: '3/5',
        description: 'NPS',
      },
    ],
  },
  nurseC: {
    title: 'Registered Nurse - Emergency',
    company: '1500 person company',
    field: 'Healthcare',
    stats: [
      {
        value: '4',
        description: 'years',
      },
      {
        value: '$80-90K',
        description: '/year',
      },
      {
        value: '4/5',
        description: 'Work-Life',
      },
      {
        value: '4/5',
        description: 'NPS',
      },
    ],
  },
  biotech: {
    title: 'Program Director',
    company: '200 person company',
    field: 'Biotechnology',
    stats: [
      {
        value: '11',
        description: 'years',
      },
      {
        value: '$150K-$200K',
        description: '/year',
      },
      {
        value: '5/5',
        description: 'Work-Life',
      },
      {
        value: '5/5',
        description: 'NPS',
      },
    ],
  },
  interiorDesigner: {
    title: 'Interior Designer',
    company: '25 person company',
    field: 'Architecture',
    stats: [
      {
        value: '1',
        description: 'year',
      },
      {
        value: '$50K-$100K',
        description: '/year',
      },
      {
        value: '4/5',
        description: 'Work-Life',
      },
      {
        value: '5/5',
        description: 'NPS',
      },
    ],
  },
  productDesigner: {
    title: 'Product Designer',
    company: '1000 person company',
    field: 'Real Estate',
    stats: [
      {
        value: '11',
        description: 'years',
      },
      {
        value: '$80K-$90K',
        description: '/year',
      },
      {
        value: '5/5',
        description: 'Work-Life',
      },
      {
        value: '5/5',
        description: 'NPS',
      },
    ],
  },
  hrCoordinator: {
    title: 'HR Coordinator',
    company: 'Over 5000 person company',
    field: 'Technology',
    stats: [
      {
        value: '2',
        description: 'years',
      },
      {
        value: '$50K-$75K',
        description: '/year',
      },
      {
        value: '5/5',
        description: 'Work-Life',
      },
      {
        value: '4/5',
        description: 'NPS',
      },
    ],
  },
  accountManager: {
    title: 'Corporate Accounting Manager',
    company: '3000 person company',
    field: 'Finance',
    stats: [
      {
        value: '8',
        description: 'years',
      },
      {
        value: '$200K',
        description: '/year',
      },
      {
        value: '5/5',
        description: 'Work-Life',
      },
      {
        value: '2/5',
        description: 'NPS',
      },
    ],
  },
  vlad: {
    title: 'VP Venture Banker',
    company: '100-200 Person Bank',
    stats: [
      {
        value: '6',
        description: 'years',
      },
      {
        value: '$150K',
        description: '/year',
      },
      {
        value: '7/10',
        description: 'Work-Life',
      },
      {
        value: '7/10',
        description: 'NPS',
      },
    ],
  },
  dan: {
    title: 'Sr UI/UX Designer',
    company: '50-100 Person Startup',
    stats: [
      {
        value: '4',
        description: 'years',
      },
      {
        value: '$130K',
        description: '/year',
      },
      {
        value: '8/10',
        description: 'Work-Life',
      },
      {
        value: '7/10',
        description: 'NPS',
      },
    ],
  },
  neil: {
    title: 'Founder & Lead Consultant',
    company: '20-30 Person Company',
    stats: [
      {
        value: '8',
        description: 'years',
      },
      {
        value: '$180K',
        description: '/year',
      },
      {
        value: '6/10',
        description: 'Work-Life',
      },
      {
        value: '7/10',
        description: 'NPS',
      },
    ],
  },
  baruch: {
    title: 'Support Operations Manager',
    company: '300-400 Person Company',
    stats: [
      {
        value: '10',
        description: 'years',
      },
      {
        value: '$175K',
        description: '/year',
      },
      {
        value: '8/10',
        description: 'Work-Life',
      },
      {
        value: '5/10',
        description: 'NPS',
      },
    ],
  },
  john: {
    title: 'Deployment Strategist',
    company: '500 Person Company',
    stats: [
      {
        value: '7',
        description: 'years',
      },
      {
        value: '$200K',
        description: '/year',
      },
      {
        value: '8/10',
        description: 'Work-Life',
      },
      {
        value: '7/10',
        description: 'NPS',
      },
    ],
  },
  carolyn: {
    title: 'Product Manager',
    company: '80k Person Company',
    stats: [
      {
        value: '8',
        description: 'years',
      },
      {
        value: '$250K',
        description: '/year',
      },
      {
        value: '8/10',
        description: 'Work-Life',
      },
      {
        value: '8/10',
        description: 'NPS',
      },
    ]
  },
}

export const data = {
  'default': [
    {
      question: "Work-self versus real-self?",
      profile: profiles.nurse,
      videoSrc: "1nurse-work-self-v-real-self.mp4",
      thumbnail: "1nurse-work-self-v-real-self.png",
      views: '2K',
      topic: 'work-life',
      industry: 'medicine',
      role: 'nursing',
      id: '1',
    },
    {
      question: "How do you feel about your pay?",
      profile: profiles.accountManager,
      videoSrc: "6-accountant-how-do-you-feel-about-your-pay.mp4",
      thumbnail: "6-accountant-how-do-you-feel-about-your-pay.png",
      views: '1K',
      topic: 'pay',
      industry: 'finance',
      role: 'accountant',
      id: '2',
    },
    {
      question: "Do you ever get bored?",
      profile: profiles.constructionProjectManager,
      videoSrc: "3-project-manager-construction-best-side-of-job-crop.mp4",
      thumbnail: "3-project-manager-construction-best-side-of-job-crop.png",
      tags: ['project-manager', 'construction', 'satisfaction'],
      views: '950',
      topic: 'satisfaction',
      industry: 'construction',
      role: 'project-manager',
      id: '3',
    },
    {
      question: "Impact on mental health?",
      profile: profiles.interiorDesigner,
      videoSrc: "3interior-designer-impact-of-work-on-mental-health.mp4",
      thumbnail: "3interior-designer-impact-of-work-on-mental-health.png",
      tags: ['work-life', 'design', 'medicine'],
      views: '10K',
      topic: 'work-life',
      industry: 'medicine',
      role: 'design',
      id: '4',
    },
    {
      question: "Highlight from the past year?",
      profile: profiles.productDesigner,
      videoSrc: "4web-designer-highlight-from-past-year-crop.mp4",
      thumbnail: "4web-designer-highlight-from-past-year-crop.png",
      views: '10K',
      topic: 'satisfaction',
      industry: 'tech',
      role: 'design',
      id: '5',
    },
    {
      question: "Who is biotech right for?",
      profile: profiles.biotech,
      videoSrc: "2biotech-why-do-this-job.mp4",
      thumbnail: "2biotech-why-do-this-job.png",
      tags: ['skills', 'product', 'tech'],
      views: '4K',
      topic: 'skills',
      industry: 'tech',
      role: 'product',
      id: '6',
    },
    {
      question: "Are you doing what you love?",
      profile: profiles.hrCoordinator,
      videoSrc: "5hr-rep-is-this-the-career-you-envisioned.mp4",
      thumbnail: "5hr-rep-is-this-the-career-you-envisioned.png",
      tags: ['satisfaction', 'hr', 'tech'],
      views: '800',
      topic: 'satisfaction',
      industry: 'tech',
      role: 'hr',
      id: '7',
    },
    /*
    {
      question: "Impact on mental health?",
      profile: profiles.nurse,
      videoSrc: "1nurse-impact-on-mental-health.mp4",
      thumbnail: "1nurse-impact-on-mental-health.png",
      tags: ['nursing', 'work-life', 'medicine'],
      views: '7K',
      topic: 'work-life',
      industry: 'medicine',
      role: 'nursing',
      id: '8',
    },*/
  ],
  'nurse': [
    {
      question: "Work-self versus real-self?",
      profile: profiles.nurse,
      videoSrc: "1nurse-work-self-v-real-self.mp4",
      thumbnail: "1nurse-work-self-v-real-self.png",
      tags: ['nursing', 'work-life', 'medicine'],
      views: '2K',
      topic: 'work-life',
      industry: 'medicine',
      role: 'nursing',
      id: '9',
    },
    {
      question: "Worst part of your job?",
      profile: profiles.nurseA,
      videoSrc: "YT-NurseA-Grossest-Part-comp.mp4",
      thumbnail: "YT-NurseA-Grossest-Part-comp.png",
      tags: ['nursing', 'satisfaction', 'medicine'],
      views: '12K',
      topic: 'satisfaction',
      industry: 'medicine',
      role: 'nursing',
      id: '10',
    },
    {
      question: "How is the industry changing?",
      profile: profiles.nurseB,
      videoSrc: "YT-NurseB-Male-Nurses-comp.mp4",
      thumbnail: "YT-NurseB-Male-Nurses-comp.png",
      tags: ['nursing', 'skills', 'medicine'],
      views: '7K',
      track: 'nursing',
      topic: 'skills',
      industry: 'medicine',
      role: 'nursing',
      id: '11',
    },
    {
      question: "Flexibility within the job?",
      profile: profiles.nurseC,
      videoSrc: "YT-NurseC-Career-Exploration-comp.mp4",
      thumbnail: "YT-NurseC-Career-Exploration-comp.png",
      tags: ['nursing', 'satisfaction', 'medicine'],
      views: '6K',
      track: 'nursing',
      topic: 'satisfaction',
      industry: 'medicine',
      role: 'nursing',
      id: '12',
    },
    {
      question: "How much do you make?",
      profile: profiles.nurseA,
      videoSrc: "YT-NurseA-Pay-comp.mp4",
      thumbnail: "YT-NurseA-Pay-comp.png",
      tags: ['nursing', 'pay', 'medicine'],
      views: '10K',
      topic: 'pay',
      industry: 'medicine',
      role: 'nursing',
      id: '13',
    },
    {
      question: "Most lucrative track?",
      profile: profiles.nurseC,
      videoSrc: "YT-NurseC-Most-Lucrative-compressed.mp4",
      thumbnail: "YT-NurseC-Most-Lucrative-compressed.png",
      tags: ['nursing', 'pay', 'medicine'],
      views: '12K',
      topic: 'pay',
      industry: 'medicine',
      role: 'nursing',
      id: '14',
    },
    {
      question: "Impact on mental health?",
      profile: profiles.nurse,
      videoSrc: "1nurse-impact-on-mental-health.mp4",
      thumbnail: "1nurse-impact-on-mental-health.png",
      tags: ['nursing', 'work-life', 'medicine'],
      views: '7K',
      topic: 'work-life',
      industry: 'medicine',
      role: 'nursing',
      id: '15',
    },
    {
      question: "Toughest part of your job?",
      profile: profiles.nurseC,
      videoSrc: "YT-NurseC-Compassion-Fatigue-comp.mp4",
      thumbnail: "YT-NurseC-Compassion-Fatigue-comp.png",
      tags: ['nursing', 'skills', 'medicine'],
      views: '8K',
      topic: 'skills',
      industry: 'medicine',
      role: 'nursing',
      id: '16',
    },
    {
      question: "Who does not do well?",
      profile: profiles.nurse,
      videoSrc: "1nurse-who-would-not-do-well.mp4",
      thumbnail: "1nurse-who-would-not-do-well.png",
      tags: ['nursing', 'skills', 'medicine'],
      views: '5K',
      topic: 'skills',
      industry: 'medicine',
      role: 'nursing',
      id: '17',
    },
    {
      question: "Level of autonomy?",
      profile: profiles.nurseA,
      videoSrc: "YT-NurseA-Decision-Making-Power-comp.mp4",
      thumbnail: "YT-NurseA-Decision-Making-Power-comp.png",
      tags: ['nursing', 'satisfaction', 'medicine'],
      views: '5K',
      topic: 'satisfaction',
      industry: 'medicine',
      role: 'nursing',
      id: '18',
    },
    /*
    {
      question: "What has surprised you?",
      profile: profiles.nurseB,
      videoSrc: "YT-NurseB-Work-Antics-comp.mp4",
      thumbnail: "YT-NurseB-Work-Antics-comp.png,
      views: '3K',
    },
    {
      question: "Difference between real you?",
      profile: profiles.nurse,
      videoSrc: "1nurse-work-you-v-real-you.mp4",
      thumbnail: "1nurse-work-you-v-real-you.png,
      views: '2.5K',
    },
    {
      question: "** Question **",
      profile: profiles.nurseA,
      videoSrc: "YT-NurseA-Work-Life-comp.mp4",
      thumbnail: "YT-NurseA-Work-Life-comp.png,
      views: 'XK',
    },
    {
      question: "** Question **",
      profile: profiles.nurseB,
      videoSrc: "YT-NurseB-Gender-Discrimination-comp.mp4",
      thumbnail: "YT-NurseB-Gender-Discrimination-comp.png,
      views: 'XK',
    },
    {
      question: "** Question **",
      profile: profiles.nurseC,
      videoSrc: "YT-NurseC-People-Interactions-comp.mp4",
      thumbnail: "YT-NurseC-People-Interactions-comp.png,
      views: 'XK',
    },*/
  ],
  'vlad': [
    {
      question: "How many hours do you work?",
      profile: profiles.vlad,
      videoSrc: "vlad-hours-orig.mov",
      thumbnail: "vlad-hours-photo.png",
      tags: ['sales', 'tech', 'effort'],
      views: '4K',
      topic: 'effort',
      industry: 'tech',
      role: 'sales',
      id: '19',
    },
    {
      question: "What's cool about your job?",
      profile: profiles.vlad,
      videoSrc: "vlad-cool-about-job.mov",
      thumbnail: "vlad-cool-about-job.png",
      tags: ['sales', 'tech', 'satisfaction'],
      views: '2K',
      topic: 'satisfaction',
      industry: 'tech',
      role: 'sales',
      id: '20',
    },
  ],
  /*
  {
    question: "Has your career evolved?",
    profile: profiles.interiorDesigner,
    videoSrc: "3hospital-interior-design-how-has-your-career-evolved.mp4",
    thumbnail: "3hospital-interior-design-how-has-your-career-evolved.png,
    views: '5K',
  },
  {
    question: "What was your path?",
    profile: profiles.dan,
    videoSrc: "/videos/dan-background-crop.mp4",
    thumbnail: "/videos/dan-background-crop.png,
    views: '300',
  },
  {
    question: "What makes you good?",
    profile: profiles.neil,
    videoSrc: "/videos/neil-why-your-good.mp4",
    thumbnail: "/videos/neil-why-your-good.png,
    views: '800',
  },
  {
    question: "What did you wish you knew?",
    profile: profiles.neil,
    videoSrc: "/videos/neil-whatiwishihadknown.mp4",
    thumbnail: "/videos/neil-whatiwishihadknown.png,
    views: '3K',
  },
  {
    question: "Would you do it again?",
    profile: profiles.baruch,
    videoSrc: "/videos/baruch-doitagain.mp4",
    thumbnail: "/videos/baruch-doitagain.png,
    views: '8K',
  },
  {
    question: "What surprised you?",
    profile: profiles.john,
    videoSrc: "/videos/john-surprise.mp4",
    thumbnail: "/videos/john-surprise.png,
    views: '5K',
  },
  {
    question: "What was your path?",
    profile: profiles.carolyn,
    videoSrc: "/videos/carolyn-path.mp4",
    thumbnail: "/videos/carolyn-path.png,
    views: '100',
  }*/
};

export const allVideos = [
  ...data['default'],
  ...data['nurse'],
  ...data['vlad'],
]

export const industryFilters = [
  {
    text: 'Show All Industries',
    id: 'all-industry',
  },
  {
    text: 'Medicine',
    id: 'medicine',
  },
  {
    text: 'Tech',
    id: 'tech',
  },
  {
    text: 'Financne',
    id: 'finance',
  },
  {
    text: 'Law',
    id: 'law',
  },
  {
    text: 'Gov',
    id: 'gov',
  }
];

export const roleFilters = [
  {
    text: 'Show All Roles',
    id: 'all-roles',
  },
  {
    text: 'Marketing',
    id: 'marketing',
  },
  {
    text: 'Sales',
    id: 'sales',
  },
  {
    text: 'Lawyer',
    id: 'lawyer',
  },
  {
    text: 'Product',
    id: 'product',
  },
  {
    text: 'Nursing',
    id: 'nursing',
  }
]

export const roleTextMap = {
  'marketing': 'Marketing',
  'sales': 'Sales',
  'lawyer': 'Lawyer',
  'accountant': 'Accountant',
  'product': 'Product',
  'nursing': 'Nursing',
  'hr': 'Human Resources',
  'project-manager': 'Project Manager',
  'design': 'Designer',
}

export const topicFilters = [
  {
    text: 'Show All Topics',
    id: 'all-topics',
  },
  {
    text: 'Work Life',
    id: 'work-life',
  },
  {
    text: 'Pay',
    id: 'pay',
  },
  {
    text: 'Satisfaction',
    id: 'satisfaction',
  },
  {
    text: 'Effort',
    id: 'effort',
  },
  {
    text: 'Skills',
    id: 'skills',
  }
]

export const tooltipText = [
  `Let's Go`,
  `Next`,    //`See more from one professional`,
  `Next`,    //`See perspectives on the same role`,
  `Next`,    //`View different roles within an industry`,
  `Next`,    //`Or explore insights across industries`,
  `Next`,    //`Explore 1000s of careers`,
  `Next`,    //`Get honest insights`,
  `Next`,    //`And find your fit`,
  `Next`,    //`And find your fit`,
]

export default {
  profiles,
  data,
  tooltipText,
  topicFilters,
  industryFilters,
  roleFilters,
  roleTextMap,
  allVideos,
}
