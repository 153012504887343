import React, { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Icon from '../components/icon';
import LogoButtonStyle from '../components/logo-button-style';
import ReactPlayer from 'react-player'
import { identify, track } from '../helpers/analytics.js';

function VideoInSideScroll(props) {

  function trackStart(data) {
    track("Video Started", data)
  }

  function trackEnd(data) {
    track("Video Ended", data)
  }

  return (
    <div style={styles.videoContainer}>
      <div style={styles.questionContainer}>
        { props.title }
      </div>
      <ReactPlayer
        onStart={trackStart.bind(null, { title: props.title, src: props.src })}
        onEnded={trackEnd.bind(null, { title: props.title, src: props.src })}
        width={500}
        height={287}
        style={styles.video}
        url={props.src}
        controls={true}
      />
    </div>
  );
}

const styles = {
  videoContainer: {
    position: 'relative',
  },
  questionContainer: {
    display: 'flex',
    top: 0,
    width: '100%',
    fontWeight: '600',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '18px',
    color: 'white',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
}

export default VideoInSideScroll;
