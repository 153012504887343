import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import FilterRow from '../components/filter-row';
import SearchResultsList from '../components/search-results-list';
import './App.css';
import logo from '../logo.svg';
import { addRemoveItem } from '../helpers/add-remove-filter';
import { industryFilters, roleFilters, topicFilters } from '../data.js'

import algoliasearch from 'algoliasearch'

const ALGOLIA_APP_ID = 'QD6CDUDZQ9'
const ALGOLIA_SEARCH_KEY = 'e2b2040845deebfc9a061569ce5d61cb'

const algoliaClient = algoliasearch(
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY
);

const videoIndex = algoliaClient.initIndex('dev_level-videos');


function App() {
  let [searchText, setSearchText] = useState(null);
  let [results, setResults] = useState(null);
  let [currentIndustryFilters, setIndustryFilter] = useState([])
  let [currentRoleFilters, setRoleFilters] = useState([])
  let [currentTopicFilters, setTopicFilters] = useState([])

  function onFilterIndustry(filter) {
    if (filter === 'all-industry') {
      setIndustryFilter([]);
    } else {
      setIndustryFilter(addRemoveItem(currentIndustryFilters, filter));
    }
  }

  function onFilterRole(filter) {
    if (filter === 'all-roles') {
      setRoleFilters([]);
    } else {
      setRoleFilters(addRemoveItem(currentRoleFilters, filter));
    }
  }

  function onFilterTopic(filter) {
    if (filter === 'all-topics') {
      setTopicFilters([]);
    } else {
      setTopicFilters(addRemoveItem(currentTopicFilters, filter));
    }
  }

  useEffect(async () => {
    const responses = await videoIndex.search(
      searchText || '',
      {
        hitsPerPage: 200,
      }
    );

    setResults(responses.hits);

  }, [searchText])

  return (
    <div>
      <div style={styles.headerGroup}>
        <Header searchText={searchText} setSearchText={setSearchText} />
      </div>
      <div style={styles.content}>
        <SearchResultsList
          searchText={searchText}
          results={results}
        />
      </div>
    </div>
  );
}

const styles = {
  headerGroup: {
    width: '100%',
    position: 'fixed',
    backgroundColor: 'white',
    zIndex: 2,
  },
  content: {
    paddingTop: '80px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
  },
}

export default App;
