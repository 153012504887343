import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import EmailSignup from '../components/email-signup.js';
import Header from '../components/header'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { allVideos } from '../data';
import { identify, track } from '../helpers/analytics.js';
import SideScrollList from '../components/side-scroll-list';
import YoutubeThumbnailForGuide from '../components/youtube-thumbnail-for-guide'
import '../styles/guide.css'
import Upsell from '../components/upsell'
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap//FormControl';
import LogoButtonStyle from '../components/logo-button-style'
import { firebase } from '../modules/firebase-module.js';
import VideoInSideScroll from '../components/video-in-side-scroll';
import { Link } from "@reach/router"
import { getDavidEmail } from '../helpers/emails';


function listToComponents(list) {
  return (
    list.map((item, index) => (
     {
       name: `video-${index}`,
       content: (<VideoInSideScroll
          title={item.question}
          src={item.src}
        />)
      }
    ))
  );
}

function clickLink(event) {
  const url = event.target.getAttribute('data-url');
  track("Link Clicked", { url: url })
  window.open(url)
}


function PersonalGuide(props) {

  let [suggestion, setSuggestion] = useState(null);
  let [showUpsell, setShowUpsell] = useState(false);

  let upsell = (
    <Upsell
      cancelButton={(
        <Button
          onClick={onShowUpsell}
          type="submit"
          variant="secondary">
          { 'See More Content' }
        </Button>
      )}
      onClose={onShowUpsell}
    />
  )

  function onShowUpsell(event) {
    setShowUpsell(!showUpsell)
  }

  function handleChange(event) {
    let sug = event.target.value
    setSuggestion(sug);
  }

  function sendSuggestion() {
    track("Suggestion Submitted", {
      data: suggestion,
    })
  }


  function onChangeAccordion(event, isOpened) {
    let summary = event.currentTarget && event.currentTarget.children && event.currentTarget.children[0] && event.currentTarget.children[0].innerText
    if (isOpened) {
      track("Opened Accordion", {
        summary,
      })
    } else {
      track("Closed Accordion", {
        summary,
      })
    }
  }

  return (
    <div>
      <Header showSearch={false} showSignOut={true} />
      <div style={styles.content}>
        <h2>Your Level Guide</h2>
        <p>Below is a personalized guide for your next career step. Use the questions to help you decide if it is right for you</p>
        <br />
        <br />
         <div style={styles.profileCardContainer}>
          <div style={styles.avatarContainer}>
            <Avatar
              name="David Millar"
              src="/images/david.jpeg"
              round={true}
              size="160px"
            />
            <div style={styles.companyLogo}>
              <Avatar
                src="/images/pachama.jpeg"
                round={true}
                size="40px"
              />
            </div>
          </div>
          <div style={styles.futureDetailsContainer}>
            <div style={styles.fakeTitle} className="possible-job-title">Sales Operations</div>
            <div style={styles.fakeJob}>@ Pachama</div>
            <div style={styles.badgeList}>
              <Badge style={styles.badge} pill variant="success">
                Mission-driven
              </Badge>
              <Badge style={styles.badge} pill variant="primary">
                Good Connection
              </Badge>
            </div>
          </div>
        </div>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Why do you think I would enjoy this?
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionMixedContent}>
              <ul>
                <li>
                  We know you have an interest in <strong>saving forests</strong> and <strong>fighting climate change</strong>.
                </li>
                <li>
                  <a target="_blank" href="https://pachama.com/">Pachama</a> is a new startup that helps people offset their emissions and restoring nature by using Machine Learning to identify the best reforestation and avoided deforestation projects for carbon credits to fund.
                </li>
                <li>
                  Learn more with <span style={styles.fakeLink} data-url="https://www.ycombinator.com/library/6R-how-pachama-uses-tech-to-help-solve-climate-change"  onClick={clickLink}>this podcast</span> featuring the CEO & your friend <strong>Gustaf Alstromer!</strong>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            What are the challenges?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                One challenge is that Pachama is not hiring a <span data-url="https://jobs.lever.co/pachama" style={styles.fakeLink} onClick={clickLink}>Sales Operations</span> role right now.
              </li>
              <li>
                However, Pachama does interface with <span data-url="https://pachama.com/organizations" style={styles.fakeLink} onClick={clickLink}>organizations</span> and does have active employees functioning in <span data-url="https://pachama.com/about" style={styles.fakeLink} onClick={clickLink}>Business Development & Operations.</span>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            What about pay and benefits?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                At a small startup, you'll likely see a drop in your base salary.
              </li>
              <li>
                However, you'll have the opportunity for increased equity in work that is meaningful to you.
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            What could my life be like in 5 years?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                At a small, data-driven company, we think you'll have the opportunity to wear many more hats and learn technical skills on the job to increase your options later.
              </li>
              <li>
                For someone with both Sales Operations & Data Science experience, checkout <span data-url="https://www.linkedin.com/in/innakg/" style={styles.fakeLink} onClick={clickLink}>Inna Kaler</span> who started a career as a Sales Operations Analyst and is now in Data Science at Patreon. Ask her anything with <span data-url="/questions/03481" style={styles.fakeLink} onClick={clickLink}>Level's AMA tool</span>.
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            I'm interested! How do I take the next step?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                Email <span style={styles.fakeLink} onClick={clickLink} data-url={getDavidEmail()}>Gustaf</span> to leverage your connection to Pachama!
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <div style={styles.center}>
          <h4>Want more?</h4>
          <p>Request another personalized Level guide</p>
          <Button
            style={styles.requestGuide}
            onClick={onShowUpsell}
          >
            Request A Guide
          </Button>
        </div>
        { showUpsell && upsell }
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

const styles = {
  requestGuide: {
    borderRadius: '30px',
  },
  sideScrollContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  futureDetailsContainer: {
    marginLeft: '15px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
  },
  fakeTitle: {
    fontSize: '20px',
    fontWeight: 700,
  },
  fakeJob: {
    marginBottom: '10px',
    fontSize: '15px',
    fontWeight: 300,
  },
  signOutLink: {
    fontSize: '12px',
  },
  profileCardContainer: {
    marginBottom: '20px',
    display: 'flex',
    height: '160px',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  companyLogo: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  avatarContainer: {
    width: '160px',
    position: 'relative',
  },
  content: {
    padding: '40px',
    width: '100%',
  },
  badgeList: {
    display: 'flex',
    flexDirection: 'row',
  },
  badge: {
    marginRight: '10px',
  },
  fakeLink: {
    color: 'blue',
    textDecoration: 'underline',
  },
  accordionMixedContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}

export default PersonalGuide;
