import React, { useState, useEffect, useRef } from 'react';
import { identify, track } from '../helpers/analytics.js';
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import InputGroup from 'react-bootstrap/InputGroup';
import { firebase } from '../modules/firebase-module.js';


function VoteControls(props) {

  return (
    <div style={styles.toggle}>
      <div style={styles.voteButton} onClick={props.onUpVote.bind(null, props.id, props.votes)}>
        <IconButton size="small">
          <Icon id="upVote" />
        </IconButton>
      </div>
      <div style={styles.votes}>{props.votes}</div>
      <div style={styles.voteButton} onClick={props.onDownVote.bind(null, props.id, props.votes)}>
        <IconButton size="small">
          <Icon fontSize="large" id="downVote" />
        </IconButton>
      </div>
    </div>
  );
}

const styles = {
  toggle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'column',
    marginRight: '15px',
  },
  votes: {
    textAlign: 'center',
  },
}

export default VoteControls;
