import React from 'react';

function EmptyFrame(props) {
  return (
    <div style={styles.container}>
      { props.children }
    </div>
  );
}

const styles = {
  container: {
    position: 'relative',
    height: '648px',
    width: '310px',
    borderRadius: '30px',
  },
}

export default EmptyFrame;
