import React, { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Icon from '../components/icon'
import LogoButtonStyle from '../components/logo-button-style'
import Button from 'react-bootstrap/Button';
import { firebase } from '../modules/firebase-module.js';

function Header({ searchText, setSearchText, showSearch, showSignOut  }) {

  function signOut() {
    firebase.auth().signOut()
    window.location.reload();
  }

  function updateSearch(event) {
    let search = event.target.value;
    setSearchText(search);
  }

  let searchComponent = (showSearch === false)
    ? (<div style={styles.emptyDiv}></div>)
    : (
      <InputGroup style={styles.search}>
        <FormControl
          id="searchText"
          type="text"
          style={styles.searchText}
          placeholder="What career are you interested in?"
          onChange={updateSearch}
          aria-label="What career are you interested in?"
        />
        <InputGroup.Append>
          <InputGroup.Text style={styles.searchIcon}>
            <Icon id="search" />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    )

  let signOutComponent = (!showSignOut)
    ? (<div style={styles.emptyDiv}></div>)
    : (
      <Button style={{borderRadius: '30px'}} onClick={signOut} variant="secondary">Sign Out</Button>
    )

  return (
    <div style={styles.header}>
      <LogoButtonStyle />
      { searchComponent }
      { signOutComponent }
    </div>
  );
}

const styles = {
  emptyDiv: {
    width: '79px',
  },
  search: {
    width: '40%',
    backgroundColor: 'white',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    position: 'relative',
  },
  searchText: {
    backgroundColor: 'white',
    paddingRight: '35px',
    borderRadius: '30px',
  },
  searchIcon: {
    background: 'rgba(0,0,0,0)',
    marginLeft: '-45px',
    zIndex: 4,
    border: 'none',
  },
  header: {
    backgroundColor: 'white',
    zIndex: 3,
    padding: '40px',
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}

export default Header;
