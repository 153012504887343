import React from 'react';

function PhoneFrame(props) {
  return (
    <svg style={styles.phone} width="310" height="810" viewBox="0 0 388 810" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M388 0H0V810H388V0ZM49 7C25.2518 7 6 26.2518 6 50V760C6 783.748 25.2518 803 49 803H339C362.748 803 382 783.748 382 760V50C382 26.2518 362.748 7 339 7H49Z" fill="white"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="388" height="810">
    <path d="M59.5414 0H328.459C349.2 0 356.672 2.15063 364.243 6.18583C371.714 10.1413 377.827 16.2465 381.792 23.7123C385.849 31.2728 388 38.8 388 59.4194V749.838C388 770.501 385.849 777.984 381.792 785.545C377.827 793.011 371.714 799.116 364.243 803.071C356.672 807.106 349.167 809.257 328.459 809.257H59.5414C38.8333 809.257 31.3282 807.106 23.7567 803.071C16.2858 799.116 10.1729 793.011 6.208 785.545C2.15063 777.984 0 770.457 0 749.838V59.4194C0 38.8 2.15063 31.2728 6.208 23.7123C10.1729 16.2465 16.2858 10.1413 23.7567 6.18583C31.3282 2.15063 38.8 0 59.5414 0ZM59.0203 12.5443C43.5003 12.5443 37.381 14.2562 31.3837 17.46C25.4637 20.58 20.6227 25.4133 17.4933 31.3282C14.2895 37.3034 12.5823 43.2343 12.5823 59.5968V749.66C12.5823 766.023 14.2895 771.954 17.4933 777.929C20.6227 783.844 25.4637 788.677 31.3837 791.797C37.381 795.001 43.323 796.708 59.7077 796.708H328.292C344.677 796.708 350.619 795.001 356.616 791.797C362.536 788.677 367.377 783.844 370.507 777.929C373.711 771.954 375.418 766.023 375.418 749.66V59.5968C375.418 43.2343 373.711 37.3034 370.507 31.3282C367.377 25.4133 362.536 20.58 356.616 17.46C350.619 14.2562 344.422 12.5443 328.98 12.5443H300.423C297.624 12.4296 295.251 14.5852 295.102 17.3824V20.7635C295.102 30.4192 286.876 41.5271 274.294 41.5271H114.66C102.077 41.5271 92.8872 31.8714 92.8872 20.7635V17.3824C92.7378 14.5897 90.3715 12.4358 87.5771 12.5443H59.0203Z" fill="white"/>
    </mask>
    <g mask="url(#mask0)">
    <path opacity="0.25" d="M164.002 16.418H208.511C210.379 16.418 211.892 17.9318 211.892 19.7991C211.892 21.6665 210.379 23.1803 208.511 23.1803H164.002C162.135 23.1803 160.621 21.6665 160.621 19.7991C160.621 17.9318 162.135 16.418 164.002 16.418ZM224.973 16.418C226.803 16.4485 228.264 17.9507 228.244 19.78C228.223 21.6094 226.729 23.0789 224.9 23.0689C223.07 23.0589 221.593 21.5732 221.592 19.7437C221.623 17.9023 223.121 16.4238 224.962 16.418H224.973Z" fill="white"/>
    </g>
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="388" height="810">
    <path d="M59.5414 0H328.459C349.2 0 356.672 2.15063 364.243 6.18583C371.714 10.1413 377.827 16.2465 381.792 23.7123C385.849 31.2728 388 38.8 388 59.4194V749.838C388 770.501 385.849 777.984 381.792 785.545C377.827 793.011 371.714 799.116 364.243 803.071C356.672 807.106 349.167 809.257 328.459 809.257H59.5414C38.8333 809.257 31.3282 807.106 23.7567 803.071C16.2858 799.116 10.1729 793.011 6.208 785.545C2.15063 777.984 0 770.457 0 749.838V59.4194C0 38.8 2.15063 31.2728 6.208 23.7123C10.1729 16.2465 16.2858 10.1413 23.7567 6.18583C31.3282 2.15063 38.8 0 59.5414 0ZM59.0203 12.5443C43.5003 12.5443 37.381 14.2562 31.3837 17.46C25.4637 20.58 20.6227 25.4133 17.4933 31.3282C14.2895 37.3034 12.5823 43.2343 12.5823 59.5968V749.66C12.5823 766.023 14.2895 771.954 17.4933 777.929C20.6227 783.844 25.4637 788.677 31.3837 791.797C37.381 795.001 43.323 796.708 59.7077 796.708H328.292C344.677 796.708 350.619 795.001 356.616 791.797C362.536 788.677 367.377 783.844 370.507 777.929C373.711 771.954 375.418 766.023 375.418 749.66V59.5968C375.418 43.2343 373.711 37.3034 370.507 31.3282C367.377 25.4133 362.536 20.58 356.616 17.46C350.619 14.2562 344.422 12.5443 328.98 12.5443H300.423C297.624 12.4296 295.251 14.5852 295.102 17.3824V20.7635C295.102 30.4192 286.876 41.5271 274.294 41.5271H114.66C102.077 41.5271 92.8872 31.8714 92.8872 20.7635V17.3824C92.7378 14.5897 90.3715 12.4358 87.5771 12.5443H59.0203Z" fill="white"/>
    </mask>
    <g mask="url(#mask1)">
    <path d="M59.5414 0H328.459C349.2 0 356.672 2.15063 364.243 6.18583C371.714 10.1413 377.827 16.2465 381.792 23.7123C385.849 31.2728 388 38.8 388 59.4194V749.838C388 770.501 385.849 777.984 381.792 785.545C377.827 793.011 371.714 799.116 364.243 803.071C356.672 807.106 349.167 809.257 328.459 809.257H59.5414C38.8333 809.257 31.3282 807.106 23.7567 803.071C16.2858 799.116 10.1729 793.011 6.208 785.545C2.15063 777.984 0 770.457 0 749.838V59.4194C0 38.8 2.15063 31.2728 6.208 23.7123C10.1729 16.2465 16.2858 10.1413 23.7567 6.18583C31.3282 2.15063 38.8 0 59.5414 0ZM59.0203 12.5443C43.5003 12.5443 37.381 14.2562 31.3837 17.46C25.4637 20.58 20.6227 25.4133 17.4933 31.3282C14.2895 37.3034 12.5823 43.2343 12.5823 59.5968V749.66C12.5823 766.023 14.2895 771.954 17.4933 777.929C20.6227 783.844 25.4637 788.677 31.3837 791.797C37.381 795.001 43.323 796.708 59.7077 796.708H328.292C344.677 796.708 350.619 795.001 356.616 791.797C362.536 788.677 367.377 783.844 370.507 777.929C373.711 771.954 375.418 766.023 375.418 749.66V59.5968C375.418 43.2343 373.711 37.3034 370.507 31.3282C367.377 25.4133 362.536 20.58 356.616 17.46C350.619 14.2562 344.422 12.5443 328.98 12.5443H300.423C297.624 12.4296 295.251 14.5852 295.102 17.3824V20.7635C295.102 30.4192 286.876 41.5271 274.294 41.5271H114.66C102.077 41.5271 92.8872 31.8714 92.8872 20.7635V17.3824C92.7378 14.5897 90.3715 12.4358 87.5771 12.5443H59.0203Z" fill="#1F2042"/>
    <path d="M164.002 16.418H208.511C210.379 16.418 211.892 17.9318 211.892 19.7991C211.892 21.6665 210.379 23.1803 208.511 23.1803H164.002C162.135 23.1803 160.621 21.6665 160.621 19.7991C160.621 17.9318 162.135 16.418 164.002 16.418ZM224.973 16.418C226.803 16.4485 228.264 17.9507 228.244 19.78C228.223 21.6094 226.729 23.0789 224.9 23.0689C223.07 23.0589 221.593 21.5732 221.592 19.7437C221.623 17.9023 223.121 16.4238 224.962 16.418H224.973Z" fill="black" fillOpacity="0.2"/>
    </g>
    </svg>
  );
}

const styles = {
  phone: {
    zIndex: 3,
    position: 'absolute',
    height: '648px',
    //width: '366px',
  }
}

export default PhoneFrame;
