import React, { useState, useEffect } from 'react';
import Demo from './pages/Demo';
import WarmIntro from './pages/WarmIntro';
import WarmIntroForm from './pages/WarmIntroForm';
import WarmIntroCustomForm from './pages/WarmIntroCustomForm';
import FilterApp from './pages/FilterApp';
import SearchApp from './pages/SearchApp';
import QuestionPage from './pages/QuestionPage';
import QuestionPageInna from './pages/QuestionPageInna'
import VideoPage from './pages/VideoPage';
import VideoPage3 from './pages/VideoPage3';
import IndexPage from './pages/IndexPage';
import RecordingPage from './pages/RecordingPage';
import { AuthContext, AuthProvider } from './helpers/auth-context'
import PersonalGuide from './pages/PersonalGuide';
import PersonalGuide2 from './pages/PersonalGuide2';
import { Router, Redirect, navigate } from "@reach/router";

import useWindowSize from './helpers/window-size-hook'
import ScrollPositionContext from './helpers/scroll-position-context'
import useScrollPosition from './helpers/scroll-position-hook'
import WindowSizeContext from './helpers/window-size-context'
import getScreenSize from './helpers/get-screen-size'
import PrivateRoute from './components/private-route'


function AppWrapper() {
  const [user, setUser] = useState(null);

  const updateUser = newUser => {
    setUser(newUser);
  }


  const scrollY = useScrollPosition();
  const windowSize = useWindowSize();

  return (
    <AuthProvider
      value={{
        user,
        updateUser
      }}
    >
      <ScrollPositionContext.Provider value={scrollY}>
        <WindowSizeContext.Provider value={windowSize}>
          <Router>
            <PrivateRoute as={IndexPage} path="/index" />
            <Demo path="/tiktok" />
            <FilterApp path="/filter-app" />
            <FilterApp path="/prototype-2" />
            <SearchApp path="/prototype-3" />
            <VideoPage path="/v/:videoId" />
            <VideoPage3 path="/v3/:videoId" />
            <WarmIntro path="/intro" />
            <WarmIntroForm path="/intro-form" />
            <WarmIntroCustomForm path="/intro-custom" />
            <RecordingPage path="/record" />
            <PrivateRoute as={QuestionPage} path="/questions" />
            <PrivateRoute as={QuestionPageInna} path="/questions/03481" />
            <PrivateRoute as={PersonalGuide} path="/guide/32510" />
            <PrivateRoute as={PersonalGuide2} path="/guide/83506" />
          </Router>
        </WindowSizeContext.Provider>
      </ScrollPositionContext.Provider>
    </AuthProvider>
  );
}

export default AppWrapper;
