import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/header'
import { allVideos } from '../data';
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import { Link } from "@reach/router"

function IndexPage(props) {

  return (
    <div style={{ margin: '20px' }}>
      <h3>Prototypes</h3>
      <ul>
        <li><Link to="/tiktok">Original Demo</Link></li>
        <li><Link to="/filter-app">Filter Experience</Link></li>
        <li><Link to="/prototype-3">Search Experience</Link></li>
        <li><Link to="/record">VideoAsk Tool</Link></li>
        <li><Link to="/questions">AMA Prototype</Link></li>
        <li><Link to="/guide/32510">Personalized Guide (for Brendan)</Link></li>
        <li><Link to="/guide/83506">Personalized Guide (for Dave)</Link></li>
        <li><Link to="/intro">Warm Intro</Link></li>
        <li><Link to="/intro-form">Warm Intro Form</Link></li>
      </ul>
    </div>
  );
}

const styles = {
}

export default IndexPage;
