import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import EmailSignup from '../components/email-signup.js';
import Header from '../components/header'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { allVideos } from '../data';
import { identify, track } from '../helpers/analytics.js';
import SideScrollList from '../components/side-scroll-list';
import YoutubeThumbnailForGuide from '../components/youtube-thumbnail-for-guide'
import '../styles/guide.css'
import Upsell from '../components/upsell'
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap//FormControl';
import LogoButtonStyle from '../components/logo-button-style'
import { firebase } from '../modules/firebase-module.js';
import VideoInSideScroll from '../components/video-in-side-scroll';
import { Link } from "@reach/router"
import { Center, Button, ChakraProvider, Box, Heading, Image } from "@chakra-ui/react"
//import './WarmIntro.css';

function WarmIntro(props) {

  return (
    <ChakraProvider>
      <header>
        <Box color="white" d="flex" h="319px" width="100%" flexDirection="column" justifyContent="center" alignItems="center" backgroundImage="url('/images/waving.svg')" backgroundRepeat="no-repeat"  backgroundPosition="center">
          <Heading size="xl" mt="0">
            Alex, can you help?
          </Heading>
          <Heading size="md" mt="5" ml="15" mr="15" mb="100px">
            <a target="_blank" href="https://www.linkedin.com/in/drodio/">Daniel Odio</a>
             &nbsp; would like to connect to &nbsp;
            <a target="_blank" href="https://www.linkedin.com/in/jeetupatel/">Jeetu Patel</a>
          </Heading>
        </Box>
      </header>
      <Box>
        <Center>
          <Button m="10" variant="outline" width="150px" colorScheme="purple" size="lg" borderRadius="80px">No</Button>
          <Button m="10" width="150px" colorScheme="purple" size="lg" borderRadius="80px">Yes!</Button>
        </Center>
        <Center>
          <Button size="lg" width="200px" colorScheme="purple" borderRadius="80px" variant="ghost">Need More Info</Button>
        </Center>
      </Box>
      <Center>
        <Image mt="50" width="500px" src="/images/connection-graph.svg" />
      </Center>
    </ChakraProvider>
  );
}

export default WarmIntro;
