import React from 'react';
import { allVideos, roleFilters, roleTextMap } from '../data.js';
import EmptyFrame from '../components/empty-frame'
import Info from '../components/info';
import BottomNav from '../components/bottom-nav';
import Question from '../components/question';
import Profile from '../components/profile';
import TopControls from '../components/top-controls'
import BottomControls from '../components/bottom-controls'
import StaticImage from '../components/static-screen'
import VideoThumbnail from '../components/video-thumbnail'
import { CSSTransitionGroup } from 'react-transition-group'


function ResultsList({ selectedRoles, selectedIndustries, selectedTopics }) {

  let results = allVideos.filter((item) => {
    if ((selectedIndustries[0] === 'all-industry' || selectedIndustries.includes(item.industry)) &&
        (selectedRoles[0] === 'all-roles' || selectedRoles.includes(item.role)) &&
        (selectedTopics[0] === 'all-topics' || selectedTopics.includes(item.topic))) {
      return true;
    }
    return false;
  })

  let organizedResultsMap = {};
  roleFilters.map((role) => {
    if (role.id !== 'all-roles') {
      organizedResultsMap[role.id] = [];
    }
  })

  results.map((result) => {
    if (organizedResultsMap[result.role]) {
      organizedResultsMap[result.role].push(result);
    } else {
      organizedResultsMap[result.role] = [];
      organizedResultsMap[result.role].push(result);
    }
  })

  let sortedKeys = Object.keys(organizedResultsMap).sort((a,b) => {
    let aSize = organizedResultsMap[a].length;
    let bSize = organizedResultsMap[b].length;
    if (aSize > bSize) return -1
    if (aSize < bSize) return 1
    return 0
  });

  let resultsList = [];
  sortedKeys.forEach((section) => {
    let sectionResults = organizedResultsMap[section].map((result, i) => {
      return (
        <VideoThumbnail key={result.thumbnail} id={result.id} src={result.thumbnail} />
      )
    })

    if (sectionResults.length > 0) {
      resultsList.push(
        <div key={`section-group-${section}`}>
          <div key={`section-header-${section}`} style={styles.header}>{roleTextMap[section]}</div>
          {sectionResults}
        </div>
      )
    }
  })

  if (resultsList.length === 0) {
    return (
      <div style={styles.center}>
        <div style={styles.title}>
          Sorry, no results :(
        </div>
        <div style={styles.subtext}>
          If you'd like to see content, request it from hello@trylevel.app !
        </div>
      </div>
    )
  }

  return (
    <div style={styles.results}>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}>
        { resultsList }
      </CSSTransitionGroup>
    </div>
  );
}

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '200px',
  },
  header: {
    fontSize: '20px',
    fontWeight: '700',
  },
  title: {
    fontSize: '20px',
  },
  subtext: {
    fontSize: '14px',
  },
  results: {
    marginLeft: '75px',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
  }
}

export default ResultsList;
