import React from 'react';
import './carousel-control-styles.scss';
import classNames from 'classnames';

function CarouselControlPrev({ onClick, enabled}) {
  return (
    <div className="reveal">
      <div className="controls" style={styles.controlsContainer}>
        <button
          id="prev-carousel-button"
          onClick={onClick}
          aria-label="previous"
          className={classNames({
            "navigate-left": true,
            "enabled": enabled,
          })}>
          <div className="controls-arrow"></div>
        </button>
      </div>
    </div>
  );
}

const styles = {
  icon: {
    width: '30px',
  },
  controlsContainer: {
    display: 'block',
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
}


export default CarouselControlPrev;
