import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/header'
import { allVideos } from '../data';
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import { Link } from "@reach/router"

function VideoPage(props) {
  const videoEl = useRef(null);
  let video = allVideos.find((el) => (el.id === props.videoId))
  const fileName = `https://cdn.gojibe.com/level/${video.videoSrc}`;

  function playPauseVideo(event) {
    let video = videoEl.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  return (
    <div>
      <div style={styles.headerGroup}>
        <Header showSearch={false} />
      </div>
      <div style={styles.content}>
        <Link to={`/filter-app`}>
          <IconButton style={styles.backButton}>
            <Icon id="back" />
          </IconButton>
        </Link>
        <video ref={videoEl} src={fileName} style={styles.video} controls playsInline />
        <div style={styles.moreInfo}>
          <pre style={styles.videoInfo}>
            {JSON.stringify(video, null, '  ')}
          </pre>
        </div>
      </div>
    </div>
  );
}

const styles = {
  backButton: {
    margin: '15px',
  },
  videoInfo: {
    fontSize: '10px',
    paddingLeft: '80px',
  },
  video: {
    width: '300px',
    borderRadius: '20px',
    minHeight: '535px',
  },
  content: {
    margin: '40px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'row',
  },
}

export default VideoPage;
