import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import Header from '../components/header'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { allVideos } from '../data';
import { identify, track } from '../helpers/analytics.js';
import SideScrollList from '../components/side-scroll-list';
import YoutubeThumbnailForGuide from '../components/youtube-thumbnail-for-guide'
import '../styles/guide.css'
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap//FormControl';
import LogoButtonStyle from '../components/logo-button-style'
import { firebase } from '../modules/firebase-module.js';
import VideoInSideScroll from '../components/video-in-side-scroll';
import { Link } from "@reach/router"
import { getBrendanEmail } from '../helpers/emails';


function listToComponents(list) {
  return (
    list.map((item, index) => (
     {
       name: `video-${index}`,
       content: (<VideoInSideScroll
          title={item.question}
          src={item.src}
        />)
      }
    ))
  );
}

function clickLink(event) {
  const url = event.target.getAttribute('data-url');
  track("Link Clicked", { url: url })
  window.open(url)
}

const lyftDayInLife = [
  {
    question: "Any unexpected fun aspects of the job?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-generic-fun.mov",
  },
  {
    question: "What's motivating as a lyft driver?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-generic-fun2.mov",
  },
  {
    question: "What tips do you have for dealing with passengers?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-pandemic-tip3.mov",
  },
  {
    question: "Any advice in general?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-generic-fun3.mov",
  },
]

const lyftAdvice = [
  {
    question: "How hard was it to start driving?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-signup.mov",
  },
  {
    question: "What other tips would you give during the pandemic?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-pandemic-tip2.mov",
  },
  {
    question: "Who rides during the pandemic?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-pandemic-tip.mov",
  },
  {
    question: "How to know where to get rides?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-generic-tip2.mov",
  },
  {
    question: "What kind of car do you use?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/lyft-generic-tip.mov",
  },
]

const whyCruise = [
  {
    question: "How is Cruise helping people?",
    src: "https://s3-us-west-1.amazonaws.com/cdn.gojibe.com/level/brendan/cruise-why-good.mov",
  },
]

function PersonalGuide(props) {

  let [suggestion, setSuggestion] = useState(null);

  function handleChange(event) {
    let sug = event.target.value
    setSuggestion(sug);
  }

  function sendSuggestion() {
    track("Suggestion Submitted", {
      data: suggestion,
    })
  }

  function signOut() {
    firebase.auth().signOut()
    window.location.reload();
  }

  function onChangeAccordion(event, isOpened) {
    let summary = event.currentTarget && event.currentTarget.children && event.currentTarget.children[0] && event.currentTarget.children[0].innerText
    if (isOpened) {
      track("Opened Accordion", {
        summary,
      })
    } else {
      track("Closed Accordion", {
        summary,
      })
    }
  }

  return (
    <div>
      <div style={styles.content}>
        <LogoButtonStyle />
        <br />
        <br />
        <h2>Your Level Guide</h2>
        <p>Below are personalized suggestions for your next career step. Use the questions to help you decide.</p>
        <br />
        <br />
         <div style={styles.profileCardContainer}>
          <div style={styles.avatarContainer}>
            <Avatar
              name="Brendan Incorvaia"
              src="/images/brendan.jpeg"
              round={true}
              size="160px"
            />
            <div style={styles.companyLogo}>
              <Avatar
                src="/images/cruise.jpeg"
                round={true}
                size="40px"
              />
            </div>
          </div>
          <div style={styles.futureDetailsContainer}>
            <div style={styles.fakeTitle} className="possible-job-title">Autonomous Vehicle Operator</div>
            <div style={styles.fakeJob}>@ Cruise</div>
            <div style={styles.badgeList}>
              <Badge style={styles.badge} pill variant="success">
                Good Connection
              </Badge>
              <Badge style={styles.badge} pill variant="primary">
                Upward Mobility
              </Badge>
            </div>
          </div>
        </div>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Why do you think I would enjoy this?
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionMixedContent}>
              <ul>
                <li>
                  We know you have an interest in <strong>technology</strong> and <strong>helping</strong> people.
                </li>
                <li>
                  Self-driving cars are an insane technology that will have a big impact on people and society at large.
                </li>
                <li>
                  Hear from an <strong>Autonomous Vehicle Operator:</strong>
                </li>
              </ul>
              <div style={styles.sideScrollContainer}>
                <SideScrollList
                  alignCenter={false}
                  hideSingleArrow={true}
                  selected='item1'
                  list={listToComponents(whyCruise)}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            What about pay and benefits?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                $23/hour and offering benefits, 401(k), and paid time off
              </li>
              <li>
                40 hours/week, eight-hour shifts, and two consecutive days off (subject to operational needs)
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            What could my life be like in 5 years?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                Start as an operator and move into different types of engineering from there.
              </li>
              <li>
                For example, checkout <span data-url="https://www.linkedin.com/in/taewook-lucas-kang-143b2634/" style={styles.fakeLink} onClick={clickLink}>Taewook Lucas Kang</span> who was a Self Driving Operator at Google and is now a Systems Engineer at Johnson & Johnson.
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            I'm interested! How do I take the next step?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                Email <span style={styles.fakeLink} onClick={clickLink} data-url={getBrendanEmail()}>Rick Fulton</span> by leveraging your connection with Vlad!
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
        <div style={styles.profileCardContainer}>
          <div style={styles.avatarContainer}>
            <Avatar
              name="Brendan Incorvaia"
              src="/images/brendan.jpeg"
              round={true}
              size="160px"
            />
            <div style={styles.companyLogo}>
              <Avatar
                src="/images/lyft.png"
                round={true}
                size="40px"
              />
            </div>
          </div>
          <div style={styles.futureDetailsContainer}>
            <div style={styles.fakeTitle} className="possible-job-title">Lyft Driver</div>
            <div style={styles.fakeJob}>Lyft</div>
            <Badge pill variant="primary">
              Good Personality Fit
            </Badge>
          </div>
        </div>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Why do you think I would enjoy this?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                We know you enjoy <strong>socializing</strong> with people and <strong>helping</strong> them directly
              </li>
              <li>
                Driving with Lyft will allow you to socialize as the pandemic lifts and help people get to their destination
              </li>
              <li>
                Although you may not see this option as a long-term fit, you can ask about job opportunities from passengers and figure out a good next step
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            What is the day-to-day like?
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.sideScrollContainer}>
              <SideScrollList
                alignCenter={false}
                hideSingleArrow={true}
                selected='item1'
                list={listToComponents(lyftDayInLife)}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            What about pay and benefits?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                $23/hour
              </li>
              <li>
                Flexible hours
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            What could my life be like in 5 years?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>
                We suggest doing this <strong>only as long as you enjoy doing it</strong>.
              </li>
              <li>
                During these months as the pandemic lifts, you can learn a lot from your passengers
              </li>
              <li>
                We think these connections will help you figure out whether there is something better for you out there
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            I'm interested! How do I take the next step?
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionMixedContent}>
              <ul>
                <li>
                  Apply to Lyft with or without a car <a onClick={clickLink} data-url="https://www.lyft.com/drive-with-lyft" style={styles.fakeLink}>here</a>
                </li>
                <li>
                  Checkout these tips for success:
                </li>
              </ul>
              <div style={styles.sideScrollContainer}>
                <SideScrollList
                  alignCenter={false}
                  hideSingleArrow={true}
                  selected='item1'
                  list={listToComponents(lyftAdvice)}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
         <div style={styles.profileCardContainer}>
          <div style={styles.avatarContainer}>
            <Avatar
              name="Brendan Incorvaia"
              src="/images/brendan.jpeg"
              round={true}
              size="160px"
            />
            <div style={styles.companyLogo}>
              <Avatar
                name="Product Manager"
                round={true}
                size="40px"
              />
            </div>
          </div>
          <div style={styles.futureDetailsContainer}>
            <div style={styles.fakeTitle} className="possible-job-title">Product Manager</div>
            <div style={styles.fakeJob}>@ Your Favorite Tech Company</div>
            <div style={styles.badgeList}>
              <Badge style={styles.badge} pill variant="success">
                Dream Job?
              </Badge>
            </div>
          </div>
        </div>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Why do you think I would enjoy this?
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionMixedContent}>
              <ul>
                <li>
                  We know you have an interest in <strong>technology</strong> and <strong>interacting</strong> with people.
                </li>
                <li>
                  Product Managers have to interface with technology teams but also interface with lots of different departments in a business
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            How can I get into this role?
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionMixedContent}>
              <ul>
                <li>
                  One option includes finding someone technical to try and start a company with. Product managers are sometimes seen as mini-CEOs, so if you try to create a business, you will end up developing a lot of the skills required of a product manager
                </li>
                <li>
                  Take a role at any tech company that values their employees, employee retention, and allows employees to change roles within the company. By doing so, you can start off at a company in a non-Product Manager role, but move into it later. Just see the following individuals who did the same!
                  <ul>
                    <li><span data-url="https://www.linkedin.com/in/jake-brill/" style={styles.fakeLink} onClick={clickLink}>Jake Brill</span></li>
                    <li><span data-url="https://www.linkedin.com/in/bridgetpujals/" style={styles.fakeLink} onClick={clickLink}>Bridget Pujals</span></li>
                    <li><span data-url="https://www.linkedin.com/in/jessemunitzalessio/" style={styles.fakeLink} onClick={clickLink}>Jesse Munitz-Alessio</span></li>
                    <li><span data-url="https://www.linkedin.com/in/paul-janzer-8aa98a8b/" style={styles.fakeLink} onClick={clickLink}>Paul Janzer</span></li>
                    <li><span data-url="https://www.linkedin.com/in/nicolesingleton1/" style={styles.fakeLink} onClick={clickLink}>Nicole Singleton</span></li>
                  </ul>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
        <div style={styles.profileCardContainer}>
          <div style={styles.avatarContainer}>
            <Avatar
              name="Brendan Incorvaia"
              src="/images/portrait_placeholder2.png"
              round={true}
              size="160px"
            />
            <div style={styles.companyLogo}>
              <Avatar
                src="/images/question.png"
                round={true}
                size="40px"
              />
            </div>
          </div>
          <div style={styles.futureDetailsContainer}>
            <div style={styles.fakeTitle} className="possible-job-title">Thinking of something else?</div>
            <div style={styles.fakeJob}></div>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Job Title @ Company"
                aria-label="Job Title"
                aria-describedby="basic-addon2"
                onChange={handleChange}
              />
              <InputGroup.Append>
                <Button onClick={sendSuggestion} variant="outline-secondary">Submit</Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <Accordion onChange={onChangeAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            I'm not excited by the options. Can you help me find my passion?
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li>Maybe explore the Bhuddist inspired, meditation-focused, <a onClick={clickLink} data-url="https://www.yelp.com/biz/herst-wellness-san-francisco" style={styles.fakeLink}>Mind/Body Coaching</a> - rated 5 stars on Yelp</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button onClick={signOut} variant="secondary">Sign Out</Button>
      </div>
    </div>
  );
}

const styles = {
  sideScrollContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  futureDetailsContainer: {
    marginLeft: '15px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
  },
  fakeTitle: {
    fontSize: '20px',
    fontWeight: 700,
  },
  fakeJob: {
    marginBottom: '10px',
    fontSize: '15px',
    fontWeight: 300,
  },
  signOutLink: {
    fontSize: '12px',
  },
  profileCardContainer: {
    marginBottom: '20px',
    display: 'flex',
    height: '160px',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  companyLogo: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  avatarContainer: {
    width: '160px',
    position: 'relative',
  },
  content: {
    padding: '40px',
    width: '100%',
  },
  badgeList: {
    display: 'flex',
    flexDirection: 'row',
  },
  badge: {
    marginRight: '10px',
  },
  fakeLink: {
    color: 'blue',
    textDecoration: 'underline',
  },
  accordionMixedContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}

export default PersonalGuide;
