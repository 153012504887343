import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/header'
import InputComponent from '../components/input-component';
import Avatar from 'react-avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { allVideos } from '../data';
import { identify, track } from '../helpers/analytics.js';
import SideScrollList from '../components/side-scroll-list';
import Icon from '../components/icon'
import IconButton from '@material-ui/core/IconButton';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap//FormControl';
import LogoButtonStyle from '../components/logo-button-style'
import { firebase, db } from '../modules/firebase-module.js';
import VideoInSideScroll from '../components/video-in-side-scroll';
import VoteControls from '../components/vote-controls';
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player';
import { Link } from "@reach/router"
import { streamQuestions } from '../data/streams';


function QuestionPage(props) {

  let [newQuestion, setNewQuestion] = useState(null);
  let [questions, setQuestions] = useState([]);

  useEffect(() => {
    // TODO: Get UserId From URL Path?
    const userId = "K2h9OLngVLkJ64QOPaFB"
    const unsubscribe = streamQuestions(userId, {
      next: querySnapshot => {
        const questions = querySnapshot.docs.map(docSnapshot => {
          let data = docSnapshot.data();
          return {
              id: docSnapshot.id,
              asked_date: (new Date(data.asked_date).toDateString()),
              answer_date: (new Date(data.answer_date).toDateString()),
              asked_by: data.asked_by,
              answer_audio: data.answer_audio,
              answer_video: data.answer_video,
              answer_text: data.answer_text,
              question: data.question,
              votes: data.votes,
              has_answer: data.has_answer,
          }
        });
        setQuestions(questions);
        //updatePrototypes(updatedPrototypes);
      },
      error: () => {
        console.error('[Level] Failed to fetch questions')
      }
    });
    return unsubscribe;
  }, [])

  function onChangeText(e) {
    let value = e.target.value;
    setNewQuestion(value);
  }

  function getAnswer(data) {
    let videoOrAudio = null;
    if (!!data.answer_video) {
      videoOrAudio = (
        <ReactPlayer
          url={data.answer_video}
          controls={true}
        />
      )
    } else if (!!data.answer_audio) {
      videoOrAudio = (
        <ReactAudioPlayer
          src={data.answer_audio}
          controls
        />
      )
    }

    if (!data.has_answer) {
      return null;
    } else {
      return (
        <div style={styles.answerSection}>
          { videoOrAudio }
          { (<div> {data.answer_text && `"${data.answer_text}"`} </div>) }
        </div>
      )
    }
  }

  function updateVoteCount(questionId, votes, event, direction) {
    /*
    let index = questions.findIndex((q) => (q.id === questionId));
    let newQuestions = [...questions];
    let updatedQuestion = { ... newQuestions[index] };
    updatedQuestion.votes += 1*direction;
    newQuestions[index] = updatedQuestion;
    setQuestions(newQuestions);
    */
    // TODO: Get UserId From URL Path?
    const userId = "K2h9OLngVLkJ64QOPaFB"

    db.collection("users")
      .doc(userId)
      .collection("questions")
      .doc(questionId)
      .set({
        votes: votes += 1*direction,
      }, { merge: true })
      .then(() => {
        console.log('Saved question successfully')
        setNewQuestion('');
      })
      .catch((error) => {
        console.error("Error writing question: ", error);
      })

    event.preventDefault();
    event.stopPropagation();
  }

  function onSubmit() {
    if (newQuestion && newQuestion.length > 0) {
      /*
      let newQuestions = [...questions, {
        id: questions.length + 1,
        votes: 0,
        has_answer: false,
        date: 'April 20, 2021',
        question: newQuestion,
        answer_video: null,
        answer_audio: null,
        answer_text: null,
      }]

      setQuestions(newQuestions); */
      let newQuestionObj = {
        id: questions.length + 1,
        votes: 0,
        has_answer: false,
        asked_date: new Date().getTime(),
        answer_date: null,
        question: newQuestion,
        answer_video: null,
        answer_audio: null,
        answer_text: null,
        asked_by: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      }

      // TODO: Get UserId From URL Path?
      const userId = "K2h9OLngVLkJ64QOPaFB"

      db.collection("users")
        .doc(userId)
        .collection("questions")
        .add(newQuestionObj)
        .then(() => {
          console.log('Saved question successfully')
          setNewQuestion('');
        })
        .catch((error) => {
          console.error("Error writing question: ", error);
        })
    }
  }

  function onUpVote(id, votes, event) {
    updateVoteCount(id, votes, event, 1);
  }

  function onDownVote(id, votes, event) {
    updateVoteCount(id, votes, event, -1);
  }

  function sortByVotes(a,b) {
    if (a.votes <  b.votes) return 1
    if (a.votes >  b.votes) return -1
    if (a.votes == b.votes) return 0
  }

  // TODO: Get UserId From URL Path?
  const userId = "K2h9OLngVLkJ64QOPaFB"
  let allowAnswering = firebase.auth().currentUser && firebase.auth().currentUser.uid === userId;

  let answeredQuestions = questions.filter((q) => (!!q.has_answer)).sort(sortByVotes);
  let answeredQComps = answeredQuestions.map((data, i) => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${i}-content`}
          id={`panel-${i}-header`}
        >
          <div style={styles.questionContainer}>
            <VoteControls id={data.id} votes={data.votes} onUpVote={onUpVote} onDownVote={onDownVote} />
            <div>
              <div style={styles.question}>{data.question}</div>
              <div style={styles.questionDate}>Answered {data.answer_date}</div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          { getAnswer(data) }
        </AccordionDetails>
      </Accordion>
    )
  })

  let unansweredQuestions = questions.filter((q) => (!q.has_answer)).sort(sortByVotes);
  let unansweredQComps = unansweredQuestions.map((data, i) => {
    return (
      <Accordion>
        <AccordionSummary
          aria-controls={`panel-${i}-content-unanswered`}
          id={`panel-${i}-header-unanswered`}
        >
          <div style={styles.questionContainer}>
            <VoteControls id={data.id}  votes={data.votes} onUpVote={onUpVote} onDownVote={onDownVote} />
            <div>
              <div style={styles.question}>{data.question}</div>
              <div style={styles.questionDate}>Asked by <a href="https://www.linkedin.com/in/ellenfree/">Ellen Free</a> on {data.asked_date}</div>
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
    )
  });

  return (
    <div>
      <div style={styles.content}>
        <LogoButtonStyle />
        <br />
        <br />
        <br />
        <br />
        <div style={styles.profileCardContainer}>
          <div style={styles.avatarContainer}>
            <Avatar
              name="Inna Kaler"
              src="/images/inna.jpeg"
              round={true}
              size="160px"
            />
            <div style={styles.companyLogo}>
              <Avatar
                src="/images/patreon.jpeg"
                round={true}
                size="40px"
              />
            </div>
          </div>
          <div style={styles.futureDetailsContainer}>
            <div style={styles.fakeTitle} className="possible-job-title">{`Inna Kaler`}</div>
            <div style={styles.fakeJob}>Data Science Manager @ Patreon</div>
            <div style={styles.profileSummary}>Ask me anything about being a data science manager 📈, previously being sales operations analyst 👩‍🏫, or going back to school 🎓 </div>
            <div style={styles.social}>
              <a href="https://www.linkedin.com/in/innakg/"><i style={styles.socialIcon} className="fab fa-linkedin" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <h4 style={styles.heading}>Top Answers</h4>
        { answeredQComps }
        <br />
        <br />
        <br />
        <h4 style={styles.heading}>Top Questions</h4>
        { unansweredQComps }
        <br />
        <br />
        <br />
        <h4 style={styles.heading}>Ask a Question</h4>
        <Form>
          <Form.Group controlId="emailSubmit">
            <Form.Control
              as="textarea"
              rows={3}
              value={newQuestion}
              onChange={onChangeText}/>
          </Form.Group>
          <div>
            <Button
              onClick={onSubmit}
              variant="primary"
            >
            Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

const styles = {
  heading: {
    marginBottom: '20px',
  },
  sideScrollContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  futureDetailsContainer: {
    marginLeft: '15px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
  },
  fakeTitle: {
    fontSize: '20px',
    fontWeight: 700,
  },
  fakeJob: {
    maxWidth: '400px',
    fontSize: '15px',
    fontWeight: 300,
  },
  profileSummary: {
    marginTop: '10px',
    maxWidth: '400px',
    fontSize: '15px',
    fontWeight: 300,
  },
  social: {
    marginTop: '15px',
    width: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  socialIcon: {
    color: "#757575"
  },
  signOutLink: {
    fontSize: '12px',
  },
  profileCardContainer: {
    marginBottom: '20px',
    display: 'flex',
    height: '160px',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  companyLogo: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  avatarContainer: {
    width: '160px',
    position: 'relative',
  },
  content: {
    padding: '40px',
    width: '100%',
  },
  badgeList: {
    display: 'flex',
    flexDirection: 'row',
  },
  badge: {
    marginRight: '10px',
  },
  fakeLink: {
    color: 'blue',
    textDecoration: 'underline',
  },
  accordionMixedContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  question: {
    fontSize: '18px',
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'column',
    marginRight: '15px',
  },
  questionDate: {
    fontSize: '10px'
  },
  votes: {
    textAlign: 'center',
  },
  answerSection: {
    marginLeft: '50px',
    marginRight: '50px',
    marginBottom: '50px',
    display: 'flex',
    flexDirection: 'row',
  },
}

export default QuestionPage;
