import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { identify, track } from '../helpers/analytics.js';
import { firebase } from '../modules/firebase-module.js'

function EmailSignup({ cta, onSuccess }) {

  let [successfulSubmission, setSuccessfulSubmission] = useState(false);

  let onSubmit = (values, { setSubmitting }) => {
    track('Signed Up', {
      email: values.email
    })
    firebase.auth().createUserWithEmailAndPassword(values.email, 'ne3d-t0-ch4nge!')
      .then((userCredential) => {
        //identify(userCredential.user.uid, {
        identify(values.email, {
          email: values.email
        });
        setSubmitting(false);
        setSuccessfulSubmission(true);
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        setSubmitting(false);
        setSuccessfulSubmission(true);
        track(`Failed Signup`, {
          email: values.email,
          error: error.code,
        })
        if (onSuccess) onSuccess();
      });
  }

  const styles = getStyles();

  let emailForm = (
    <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form
          noValidate
          onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <Form.Control
              className="email-input"
              isInvalid={errors.email && touched.email}
              required
              type="email"
              name="email"
              onBlur={handleBlur}
              value={values.email}
              placeholder="name@example.com"
              aria-label="Email"
              aria-describedby="basic-addon2"
              onChange={handleChange}
            />
            <InputGroup.Append>
              <Button
                id="email-signup-button"
                className="email-submit"
                disabled={isSubmitting}
                type="submit"
                variant="primary">
                { cta || 'Join the Waitlist' }
              </Button>
            </InputGroup.Append>
            { !(errors.email && touched.email) && (<div style={styles.errorSpace}></div>) }
            { errors.email && touched.email &&  (
              <Form.Control.Feedback type="invalid" style={styles.errorContainer}>
                <div style={styles.invalidText}> { errors.email } </div>
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form>
      )}
    </Formik>
  )

  let successMessage = (
    <div style={styles.success}>
      { `Thank you. We'll keep you updated on our progress. ✅` || 'Success! ✅' }
    </div>
  )

  return (
    <div style={styles.emailContainer}>
      { successfulSubmission ? successMessage : emailForm }
    </div>
  )
}

function getStyles() {

  let styles = {
    success: {
      fontWeight: 600,
      color: '#5fb810',
      textAlign: 'center',
      marginBottom: '20px',
    },
    emailContainer: {
      position: 'relative',
      zIndex: 1,
      marginTop: '10px',
      width: '350px',
    },
    invalidText: {
      position: 'relative', // Prevents invalid error from moving page down
      marginTop: '4px',
    },
    errorContainer: {
      margin: '0px',
    },
    errorSpace: { // Ensure error text does not push down content
      height: '23px',
      width: '300px',
    },
  }
  return styles;
}

export default EmailSignup;
