import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Button from 'react-bootstrap/Button';
import EmailSignup from './email-signup.js';
import Icon from './icon.js';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'react-bootstrap/Spinner';
import QuestionForm from './question-form';
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
} from '../styles/global'

function Upsell({ onClose, cancelButton }) {

  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  return (
    <div style={styles.dialogContainer}>
      <div style={styles.signUpContainer}>
        <div style={styles.closeButton}>
          <IconButton
            id="close-dialog-button"
            onClick={onClose}>
            <Icon id='close' />
          </IconButton>
        </div>
        <div style={styles.signUpContent}>
          <div style={styles.header}>Sign Up</div>
          <div style={styles.price}>For just $9 / month</div>
          <br />
          <div style={styles.subText}>And get a new personalized guide <strong>every week</strong> for different jobs <em>and</em> different roles until you make your move!</div>
          <br />
          <div style={styles.cta} target="_blank">Venmo <a href="https://account.venmo.com/u/asaschachar">Level</a> to create your account!</div>
        </div>
      </div>
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {

  let signUpContainer;
  if (isDesktop) {
    signUpContainer = {
      maxWidth: '450px',
    }
  }

  if (isTablet) {
    signUpContainer = {
      maxWidth: '450px',
    }
  }

  if (isMobile) {
    signUpContainer = {
      maxWidth: '95%',
    }
  }

  let styles = {
    form: {
    },
    subText: {
      fontSize: '1.2rem',
      textAlign: 'center'
    },
    signUpContainer: {
      zIndex: 5,
      position: 'relative',
      padding: '30px',
      minWidth: '300px',
      minHeight: '300px',
      //height: '500px',
      //width: '100%',
      //height: '100%',
      background: 'white',
      borderRadius: '10px',
      ...signUpContainer,
    },
    signUpContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    dialogContainer: {
      zIndex: 5,
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      left: 0,
      top: 0,
    },
    closeButton: {
      position: 'absolute',
      right: '0px',
      top: '0%',
    },
    header: {
      fontSize: '30px',
      marginBottom: '25px',
    },
    spinner: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    price: {
      fontSize: '22px',
    },
  }

  return styles;
}

export default Upsell;
