import React from 'react';

function BottomControls(props) {
  return (
    <div style={styles.bottomControls}>
      { props.children }
    </div>
  );
}

const styles = {
  bottomControls: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
  },
}

export default BottomControls;
