
function extractFirstName(name) {
  let firstName;
  if (name.includes(' ')) {
    firstName = name.split(' ')[0];
  }
  return firstName;
}

export function getConnectorEmailLink({
    requesterName,
    requesterEmail,
    requesterBio,
    requestorLinkedIn,
    connectorName,
    connectorEmail,
    targetName,
    targetEmail,
    targetLinkedIn,
    explanation,
    askPermission,
  }) {

  let emailTo = connectorEmail;
  let emailCC = 'hello@trylevel.app';
  let emailBCC = '';
  let emailSub = `Are you open to introducing ${extractFirstName(requesterName)} to ${extractFirstName(targetName)}?`
  let emailBody = `Hey ${connectorName}, %0d%0a%0d%0a${requesterName} has submitted a request to be introduced to ${targetName}. Use the buttons below to make your decision.%0d%0a%0d%0aFrom ${extractFirstName(requesterName)}:%0d%0a%0d%0a"${explanation}"`
  return "mailto:"+emailTo+'?cc='+emailCC+'&bcc='+emailBCC+'&subject='+emailSub+'&body='+emailBody;
}

export function getYesIntroLink({
    requesterName,
    requesterEmail,
    requesterBio,
    requestorLinkedIn,
    connectorName,
    connectorEmail,
    targetName,
    targetEmail,
    targetLinkedIn,
    explanation,
  }) {

  let emailTo = targetEmail;
  let emailCC = `${requesterEmail},${connectorEmail}`;
  let emailBCC = 'hello@trylevel.app';
  let emailSub = `${extractFirstName(targetName)}, meet ${requesterName}!`
  let emailBody = `Hey ${extractFirstName(targetName)}, meet ${extractFirstName(requesterName)}.%0d%0a%0d%0a${extractFirstName(requesterName)}: "${explanation}"%0d%0a%0d%0aHope the conversation is valuable.%0d%0a%0d%0a${connectorName}`
  return "mailto:"+emailTo+'?cc='+emailCC+'&bcc='+emailBCC+'&subject='+emailSub+'&body='+emailBody;
}

export function getYesPermissionLink({
    requesterName,
    requesterEmail,
    requesterBio,
    requestorLinkedIn,
    connectorName,
    connectorEmail,
    targetName,
    targetEmail,
    explanation,
  }) {

  let emailTo = targetEmail;
  let emailCC = `${requesterEmail},${connectorEmail}`;
  let emailBCC = 'hello@trylevel.app';
  let emailSub = `Are you open to meeting ${extractFirstName(requesterName)}?`
  let emailBody = `Hey ${extractFirstName(targetName)}, meet ${extractFirstName(requesterName)}.%0d%0a%0d%0aHere's some context from ${extractFirstName(requesterName)}: "${explanation}"%0d%0a%0d%0aHope the conversation is valuable.%0d%0a%0d%0a${connectorName}`
  return "mailto:"+emailTo+'?cc='+emailCC+'&bcc='+emailBCC+'&subject='+emailSub+'&body='+emailBody;
}

export function getRequestDemoLink(content) {
  let app = capitalize(content['name'] || 'no-app');
  let emailTo = content['email']['address'] || `asa.schachar+${app}@gmail.com`
  let emailCC = ''
  let emailSub = content['email']['subject'] || `${app} Demo Request`;
  let emailBody = content['email']['body'] || `Hey ${app},%0d%0a%0d%0aI'd love to get a demo of ${app}.%0d%0a%0d%0aThanks!`
  return "mailto:"+emailTo+'?cc='+emailCC+'&subject='+emailSub+'&body='+emailBody;
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function requestDemoEmail(content) {
  window.location.href = getRequestDemoLink(content);
}

export function getBrendanEmail(content) {
  let emailTo = `tarrasq@gmail.com`
  let emailCC = 'vladimir@trylevel.app'
  let emailSub = `Re: Autonomous Vehicle Operator @ Cruise`;
  let emailBody = `Hey Rick,%0d%0a%0d%0aI'm Brendan, a friend of Vladimir Korshin. I'm interested in applying to the Autonomous Vehicle Operator role @ Cruise.%0d%0a%0d%0aLet me know if there are any recommended steps I can take to set myself up for success in getting the job. Vlad thought you could help out. Really appreciate your time in advance.%0d%0a%0d%0aThanks!%0d%0a%0d%0aBrendan`
  return "mailto:"+emailTo+'?cc='+emailCC+'&subject='+emailSub+'&body='+emailBody;
}

export function getDavidEmail(content) {
  let emailTo = `gustaf@gmail.com`
  let emailCC = 'asa@trylevel.app'
  let emailSub = `Interested in Sales Operations @ Pachama`;
  let emailBody = `Hey Gustaf,%0d%0a%0d%0aI recently decided to explore opportunities in climate change and was pointed to Pachama.%0d%0a%0d%0aAny chance you can help me evaluate whether they'd have a good role for me? Really appreciate your time in advance.%0d%0a%0d%0aThanks!%0d%0a%0d%0aDavid`
  return "mailto:"+emailTo+'?cc='+emailCC+'&subject='+emailSub+'&body='+emailBody;
}

export default {
  getConnectorEmailLink,
  getYesIntroLink,
  getYesPermissionLink,
  requestDemoEmail,
  getRequestDemoLink,
  getBrendanEmail,
  getDavidEmail,
}
