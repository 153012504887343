import React from 'react';

function FilterButtons(props) {

  let buttonList = props.buttons.map((button, i) => {
    let selectedClass = !!props.currentFilters.includes(button.id)
      ? 'selected'
      : '';

    return (
      <div
        onClick={props.onFilterClick.bind(null, button.id)}
        key={`filter-button-${i}`} smooth to={`#${button.id}`}
        className={`grow pill-link ${selectedClass}`}
      >
        <div> { button.text } </div>
      </div>
    )
  })

  return (
    <div className="" style={styles.index}>
      { buttonList }
    </div>
  )
}

const styles = {
  index: {
    padding: '0px 20px',
    letterSpacing: "inherit",
    fontSize: "14px",
  }
}

export default FilterButtons;
