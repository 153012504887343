import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { identify, track } from '../helpers/analytics.js';
import { firebase } from '../modules/firebase-module.js'

function QuestionForm({ cta, onFinished }) {

  let [questionIndex, setQuestionIndex] = useState(0);
  let [successfulSubmission, setSuccessfulSubmission] = useState(false);

  let questions = [
    {
      id: 'learn-directly-from-professionals',
      text: 'Is it useful to hear directly from professionals to learn more about a particular career?',
    },
    {
      id: 'your-interest-videos-for-career-decision',
      text: 'If we had videos from professionals working in the field(s) you are interested in, would it help you make a more informed career decision?',
    }
  ];


  function onQuestionAnswered(questionNumber, event)  {
    track("Answered Question", {
      questionNumber: questionNumber + 1,
      question: questions[questionNumber].id,
      answer: event.target.textContent,
    })
    setQuestionIndex(questionIndex + 1);

    if (questionIndex === questions.length - 1) {
      let autoClose = false;
      if (onFinished && autoClose) {
        onFinished();
      }
    }
  }

  let questionComponents = questions.map((q, i) => {
    return (
      <div>
        <div style={styles.question}> { q.text } </div>
        <div style={styles.buttons}>
          <ButtonGroup size="lg" className="mb-2">
            <Button variant="primary" onClick={onQuestionAnswered.bind(null, i)}>Yes</Button>
            <Button variant="secondary" onClick={onQuestionAnswered.bind(null, i)}>No</Button>
          </ButtonGroup>
        </div>
        <div style={styles.progress}> {`Question ${questionIndex + 1} of ${questions.length}`}</div>
      </div>
    )
  })


  let successMessage = (
    <div style={styles.success}>
      { `Thank you!` }
    </div>
  )

  let activeQuestion = questionComponents[questionIndex] || successMessage

  return (
    <div style={styles.emailContainer}>
      { activeQuestion }
    </div>
  )
}

const styles = {
  buttons: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  success: {
    fontWeight: 600,
    color: '#5fb810',
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: '20px',
  },
  progress: {
    fontSize: '10px',
    fontColor: 'gray',
    width: '100%',
    textAlign: 'right',
  },
  question: {
    marginBottom: '40px',
  },
  emailContainer: {
    position: 'relative',
    zIndex: 1,
    padding: '10px',
    width: '350px',
  },
  invalidText: {
    position: 'relative', // Prevents invalid error from moving page down
    marginTop: '4px',
  },
  errorContainer: {
    margin: '0px',
  },
  errorSpace: { // Ensure error text does not push down content
    height: '23px',
    width: '300px',
  },
}

export default QuestionForm;
