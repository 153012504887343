import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import SignUpDialog from './signup-dialog';
import Icon from './icon.js';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  largeWhitePlayButton: {
    '& svg': {
      fontSize: 75,
      color: 'rgba(255,255,255, 0.7)'
    }
  },
  largeBlackPlayButton: {
    '& svg': {
      fontSize: 75,
      color: 'rgba(0, 0, 0, 0.3)'
    }
  },
}));

function StaticScreen({ clickHandler, src }) {

  const fileName = `https://cdn.gojibe.com/level/${src}`;
  const classes = useStyles();

  let playButton = (
    <IconButton
      className={classes.largeWhitePlayButton}
      id="play-button"
      >
      <Icon id='play'/>
    </IconButton>
  )

  return (
    <>
      <img src={`/images/static_photo.png`} style={styles.video} />
      <div style={styles.clickCapture} onClick={clickHandler}>
        { playButton }
      </div>
    </>
  );
}

const styles = {
  clickCapture: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 4,
    backgroundColor: 'rgba(255,255,255,0)',
  },
  video: {
    position: 'absolute',
    //width: '100%',
    // HACK FOR WHITE LINE
    width: '101%',
    height: '100%',
    zIndex: 1,
  },
}

export default StaticScreen;
