import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import SignUpDialog from './signup-dialog';
import EmailSignup from './email-signup.js';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { track } from '../helpers/analytics'

const SHARE_ID = Math.floor(Math.random() * 1000000);

function EndScreen() {
  const [hasSignupDialog, setSignupDialog] = useState(false);

  function onShareStory() {
    track('Clicked - Share Story')
    window.open("https://my-response.plotto.com/#/60495280f114230008172eae/60495280f114230008172eaf/1");
  }

  function onCopyClick() {
    track('Clicked - Copy Share Link', {
      shareId: SHARE_ID,
    })
    navigator.clipboard.writeText(shareLink)
  }

  const shareLink = `https://demo.trylevel.app/?share=${SHARE_ID}`;

  return (
    <div style={styles.screen}>
      { hasSignupDialog && (
        <SignUpDialog
          onClose={() => (setSignupDialog(false))}
        />
      )}
      <div style={styles.header}>
        <div style={styles.logo} src="/images/level_logo.svg">LEVEL</div>
        <div style={styles.tagLine}>with us</div>
      </div>
      <div style={styles.section}>
        <EmailSignup />
      </div>
      <div style={styles.section}>
        <div style={styles.subText}>Share this custom link to be first in line for our beta 🎉</div>
        <InputGroup className="mb-3" style={styles.shareLink}>
          <Form.Control
            value={shareLink}
            disabled
            aria-label="Share Demo"
          />
          <InputGroup.Append>
            <Button
              id="copy-share-link"
              type="submit"
              onClick={onCopyClick}
              variant="primary">
              { 'Copy' }
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
      <div style={styles.section}>
        <div style={styles.subText}>Help people find work they love.</div>
        <Button
          id="share-your-story-button"
          style={styles.button}
          variant="primary"
          onClick={onShareStory}>
          Share your Story
        </Button>
      </div>
    </div>
  );
}

const styles = {
  shareLink: {
    maxWidth: '300px',
  },
  buttonGroup: {
    display: 'flex',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '40px',
  },
  tagLine: {
    fontSize: '14px',
    textAlign: 'center',
    marginTop: '-10px',
  },
  welcomeText: {
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '-15px',
  },
  logo: {
    fontSize: '35px',
    margin: '5px',
    letterSpacing: '5px',
    textIndent: '10px',
    textAlign: 'center',
  },
  subText: {
    maxWidth: '300px',
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: '15px',
  },
  screen: {
    width: '100%',
    zIndex: 4,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '30px',
  },
  button: {
    marginBottom: '10px',
    width: '160px',
  },
}

export default EndScreen;
