import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import Header from '../components/header'
import LogoButtonStyle from '../components/logo-button-style'
import InputComponent from '../components/input-component';

function RecordingPage(props) {


  return (
    <div>
      <div style={styles.content}>
        <LogoButtonStyle />
        <br />
        <br />
        <h2>Ellen's Questions</h2>
        <p>about being an Experiential Designer</p>
        <br />
        <h4>What inspired you to take on the role/project you’re working on now?</h4>
        <InputComponent />
      </div>
    </div>
  );
}

const styles = {
  sideScrollContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  futureDetailsContainer: {
    marginLeft: '15px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
  },
  fakeTitle: {
    fontSize: '20px',
    fontWeight: 700,
  },
  fakeJob: {
    marginBottom: '10px',
    fontSize: '15px',
    fontWeight: 300,
  },
  signOutLink: {
    fontSize: '12px',
  },
  profileCardContainer: {
    marginBottom: '20px',
    display: 'flex',
    height: '160px',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  companyLogo: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  avatarContainer: {
    width: '160px',
    position: 'relative',
  },
  content: {
    padding: '40px',
    width: '100%',
  },
  badgeList: {
    display: 'flex',
    flexDirection: 'row',
  },
  badge: {
    marginRight: '10px',
  },
  fakeLink: {
    color: 'blue',
    textDecoration: 'underline',
  },
  accordionMixedContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}

export default RecordingPage;
