import React from 'react';

function SideControls(props) {
  return (
    <div style={styles.controlsBox}>
      <div style={styles.controlsContainer}>
        <div style={styles.views}> { props.views } </div>
        <img style={styles.sideIcon} src="/icons/profile.svg" />
        <img style={styles.sideIcon} src="/icons/heart.svg" />
        <img style={styles.sideIcon} src="/icons/comment.svg" />
        <img style={styles.sideIcon} src="/icons/show-details.svg" />
      </div>
    </div>
  );
}

const styles = {
  views: {
    color: '#E0E0E0',
    fontWeight: '700',
    fontSize: '20px',
    width: '40px',
    height: '40px',
    textAlign: 'center',
  },
  sideIcon: {
    margin: '10px',
  },
  controlsBox: {
    alignSelf: 'flex-end',
    display: 'flex',
    width: '40px',
    justifyContent: 'flex-end',
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
  }
}

export default SideControls;
