import React from 'react';

function Mock(props) {
  return (
    <img style={styles.mock} src={props.src} />
  );
}

const styles = {
  mock: {
    height: '627px',
  }
}

export default Mock;
