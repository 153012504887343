import React, { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Icon from '../components/icon';
import LogoButtonStyle from '../components/logo-button-style';
import FilterButtons from '../components/filter-buttons';

function FilterRow(props) {

  let filterType = {
    'role': 'Role',
    'industry': 'Industry',
    'topic': 'Topic',
  }

  let filterText = filterType[props.type];

  return (
    <div style={styles.header}>
      {/*<div style={styles.filterHeader}>{`Filter by ${filterText}:`}</div>*/}
      <FilterButtons
        buttons={props.filters}
        currentFilters={props.currentFilters}
        onFilterClick={props.onFilterClick}/>
    </div>
  );
}

const styles = {
  filterHeader: {
    display: 'flex',
    fontSize: '12px',
  },
  emptyDiv: {
    width: '79px',
  },
  search: {
    width: '40%',
    backgroundColor: 'white',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    position: 'relative',
  },
  searchText: {
    backgroundColor: 'white',
    paddingRight: '35px',
    borderRadius: '30px',
  },
  searchIcon: {
    background: 'rgba(0,0,0,0)',
    marginLeft: '-45px',
    zIndex: 4,
    border: 'none',
  },
  header: {
    padding: '30px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
  }
}

export default FilterRow;
