import { firebase, db } from '../modules/firebase-module';

// TODO: Consider streaming answered & unanswered questions separately
export const streamQuestions = (userId, observer) => {
  return db.collection('users')
    .doc(userId)
    .collection('questions')
    .orderBy('votes')
    .onSnapshot(observer);
};

export default {
  streamQuestions,
}
