import React, { useState, useEffect } from 'react';
import { data } from '../data';

function Container(props) {

  return (
    <div style={styles.container}>
      { props.children }
    </div>
  );
}

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default Container;
