import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/header'
import { makeStyles } from '@material-ui/core/styles';
import Icon from '../components/icon'
import { Link } from "@reach/router"
import IconButton from '@material-ui/core/IconButton';
import algoliasearch from 'algoliasearch'

const useStyles = makeStyles((theme) => ({
  largeWhitePlayButton: {
    '& svg': {
      fontSize: 40,
      color: 'rgba(255,255,255, 0.7)'
    }
  },
}));


const ALGOLIA_APP_ID = 'QD6CDUDZQ9'
const ALGOLIA_SEARCH_KEY = 'e2b2040845deebfc9a061569ce5d61cb'

const algoliaClient = algoliasearch(
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY
);

const videoIndex = algoliaClient.initIndex('dev_level-videos');


function VideoPage3(props) {

  let [data, setData ] = useState(null);
  const classes = useStyles();

  useEffect(async () => {
    const result = await videoIndex.findObject(hit => hit.id == Number(props.videoId))
    setData(result.object);
  }, [])

  if (!data) {
    return (
      <div>Loading...</div>
    )
  }

  const index = data.id % 80
  const gender = data.id % 2 === 0 ? 'men' : 'women';
  const image = `https://randomuser.me/api/portraits/${gender}/${index}.jpg`

  const chartSettings = {
    options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        },
      },
      labels: ['Average Results'],
    },
  }

  let satisfactionSettings = {
    ...chartSettings,
    ... { labels: ['Satisfaction'], },
  }
  let satisfactionSeries = [data.satisfaction];
  let workLifeSettings = {
    ...chartSettings,
    ... { labels: ['Work/Life'], },
  }
  let workLifeSeries = [data.work_life];
  let paySettings = {
    ...chartSettings,
    ... { labels: ['Pay'], },
  }
  let paySeries = [data.pay];

  return (
    <div>
      <div style={styles.headerGroup}>
        <Header showSearch={false} />
      </div>
      <div style={styles.content}>
        <Link to={`/prototype-3`}>
          <IconButton style={styles.backButton}>
            <Icon id="back" />
          </IconButton>
        </Link>
        <div style={styles.imageButtonContainer}>
          <img style={styles.image} src={image} />
          {/*
          <IconButton
            className={classes.largeWhitePlayButton}
            id="play-button"
            >
            <Icon id='play'/>
          </IconButton> */}
          <div style={styles.moreInfo}>
            <div><strong>Company:</strong> {data.company}</div>
            <div><strong>Title:</strong> {data.title}</div>
          </div>
        </div>
        <div style={styles.questionList}>
          <div style={styles.questionHeader}>Top Questions</div>
          <div style={styles.question}>How did you get into this role?</div>
          <div style={styles.question}>What is the worst part of this role?</div>
          <div style={styles.question}>What makes someone successful?</div>
          <div style={styles.question}>How is this industry changing?</div>
          <div style={styles.question}>What surprised you about this role?</div>
        </div>
        <div style={styles.sideContainer}>
          <div style={styles.answer}>
            <div style={styles.questionHeader}>Answer</div>
            <div>...</div>
          </div>
          <div style={styles.ratingsSection}>
            <div style={styles.questionHeader}>Ratings</div>
            <div style={styles.ratings}>
              <div style={styles.rating}>
                <div style={styles.score}>
                  {data.satisfaction}
                </div>
                <div style={styles.ratingLabel}>
                  Satisfaction
                </div>
              </div>
              <div style={styles.rating}>
                <div style={styles.score}>
                  {data.work_life}
                </div>
                <div style={styles.ratingLabel}>
                  Work Life
                </div>
              </div>
              <div style={styles.rating}>
                <div style={styles.score}>
                  {data.pay}
                </div>
                <div style={styles.ratingLabel}>
                  Pay
                </div>
              </div>
            </div>
            <div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  ratingLabel: {
    textAlign: 'center',
    fontSize: '10px',
  },
  rating: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  score: {
    fontSize: '20px',
    margin: '10px',
    fontWeight: 800,
  },
  ratings: {
    display: 'flex',
    flexDirection: 'column',
  },
  sideContainer: {
    marginLeft: '50px',
  },
  question: {
    fontSize: '20px',
  },
  questionHeader: {
    marginTop: '40px',
    fontSize: '24px',
    fontWeight: 800,
  },
  image: {
    position: 'absolute',
    width: '300px',
    borderRadius: '30px',
  },
  backButton: {
    margin: '15px',
  },
  videoInfo: {
    fontSize: '10px',
    paddingLeft: '80px',
  },
  video: {
    width: '300px',
    borderRadius: '20px',
  },
  content: {
    margin: '40px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'row',
  },
  imageButtonContainer: {
    position: 'relative',
    margin: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '300px',
    height: '300px',
    minWidth: '300px',
  },
  moreInfo: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    paddingLeft: '20px',
    flexDirection: 'column',
    justifyContent: 'start',
    position: 'absolute',
    top: '100%',
  },
}

export default VideoPage3;
