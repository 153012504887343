import React from 'react';
import './carousel-control-styles.scss';
import classNames from 'classnames';

function CarouselControlNext({ onClick, enabled, tooltip }) {
  return (
    <div className="reveal">
      <div className="controls" style={styles.controlsContainer}>
        { tooltip }
        <button
          onClick={onClick}
          aria-label="next"
          className={classNames({
            "navigate-right": true,
            "enabled": enabled,
          })}>
          <div className="controls-arrow">
          </div>
        </button>
      </div>
    </div>
  );
}

const styles = {
  icon: {
    width: '30px',
  },
  controlsContainer: {
    display: 'block',
    position: 'fixed',
    bottom: 0,
    right: 0,
  },
}


export default CarouselControlNext;
