import React from 'react';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Settings from '@material-ui/icons/Settings';
import Cancel from '@material-ui/icons/Cancel';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import Search from '@material-ui/icons/Search';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Mic from '@material-ui/icons/Mic';
import VideoCam from '@material-ui/icons/Videocam';
import Subject from '@material-ui/icons/Subject';

const iconMap = {
  file: (<InsertDriveFileOutlinedIcon />),
  settings: (<Settings />),
  close: (<Cancel />),
  play: (<PlayCircleFilled />),
  search: (<Search/>),
  back: (<ArrowBack/>),
  mic: (<Mic />),
  video: (<VideoCam />),
  text: (<Subject />),
  upVote: (<ArrowDropUpIcon fontSize="inherit" />),
  downVote: (<ArrowDropDownIcon fontSize="inherit" />),
}

function Icon(props) {
  return iconMap[props.id] || null;
}

export default Icon;
